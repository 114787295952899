import { SET_AUDIT_LOGS } from '../types';
import axios from 'axios';

export const getAuditLogs = (page, size) => (dispatch) => {
    axios.get(`/v1/audit?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_AUDIT_LOGS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_AUDIT_LOGS,
                payload: []
            })
        })
}