import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import axios from 'axios';
import jwtDecode from 'jwt-decode'
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED, LOADING_UI } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/authActions';
import { getCookie } from 'redux-cookie';
// Layouts
import AuthRoute from './util/AuthRoute';
import AdminLayout from "layouts/admin/Admin.js";
import LoginForm from "layouts/LoginForm.js";
import LoginFormSuperAdmin from "layouts/LoginFormSuperAdmin.js"
/* import SignupForm from "layouts/SignupForm.js"; */
import { withTranslation } from 'react-i18next';

//axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
//axios.defaults.baseURL = "http://localhost:8199/api"
axios.defaults.baseURL = 'https://nutrition.medietetic.com/api';

console.log("-------------------axios.defaults.baseURL------------------------")
console.log(axios.defaults.baseURL)
console.log("-------------------axios.defaults.baseURL------------------------")

const token = store.dispatch(getCookie('token'));
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/';
  } else {
    store.dispatch({ type: LOADING_UI });
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    store.dispatch(getUserData())
  }
}

class App extends Component {

  render() {

    return (
      <ToastProvider placement="bottom-right">
        <Provider store={store}>
          <Router>
            <Switch>
              <AuthRoute path="/admin" protectedRoute={true} component={props => <AdminLayout {...props} />} />
              <AuthRoute exact path="/" protectedRoute={false} component={LoginForm} />
              <AuthRoute exact path="/login" protectedRoute={false} component={LoginFormSuperAdmin} />
              {/* <AuthRoute path="/signup" protectedRoute={false} component={SignupForm} /> */}
            </Switch>
          </Router>
        </Provider>
      </ToastProvider>
    )
  }
}

export default withTranslation()(App);
