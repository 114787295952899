import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Alert, Button, Form, Input, Row, Col, FormGroup, Label } from "reactstrap";
import axios from 'axios';
import { withToastManager } from 'react-toast-notifications';
import { Typeahead } from 'react-bootstrap-typeahead';
import ClipLoader from "react-spinners/ClipLoader";
import DishCard from 'components/dish/DishCard';
import FoodsCard from 'components/dish/FoodsCard';
import MacronutrientsDishCard from 'components/dish/MacronutrientsDishCard';
import MicronutrientsTableCard from "components/dish/MicronutrientsTableCard";
import MineralsTableCard from "components/dish/MineralsTableCard"
import MacronutrientsChartAndRatioTable from "components/dish/MacronutrientsChartAndRatioTable"
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
import NumberFormat from 'react-number-format';
// Redux
import { connect } from 'react-redux';
import { createDish, updateDish, updateDishApproved, getDishTypes, getPreparationTypes, getFoods, getMacronutrients, getMicronutrients, getMinerals } from '../../redux/actions/dishActions';
import { getUnitsOfMeasurement } from '../../redux/actions/foodActions';
import { getAllergenIssue } from '../../redux/actions/allergenActions';
import { getFodmapIssue } from '../../redux/actions/fodmapActions';
import store from '../../redux/store';
import { RESET_ALERGEN_DATA, RESET_DISH_DATA, RESET_FODMAP_DATA } from '../../redux/types';
// Util
import { hasRole } from '../../util/hasRole';
import { checkAuth } from '../../util/checkAuth';

class Dish extends React.Component {

    emptyDish = {
        name: '',
        types: [],
        basePreparationType: null,
        persons: 1,
        foods: [],
        approved: false
    }

    constructor(props) {
        super(props);
        this.state = {
            dish: this.emptyDish,
            initialDish: this.emptyDish,
            newName: '',
            modalOpen: false,
            editable: false,
            editableIndex: -1,
            foodExists: false,
            showPieChart: false,
            showDetailsTable: false,
            showMacroTable: false,
            showMineralsTable: false,
            foodToAdd: {
                foodId: null,
                foodName: '',
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: '',
                    symbol: ''
                },
                quantity: '',
                gramsByPiece: 0,
                preparationType: ''
            },
            deleteFoodModal: false,
            foodToDelete: {
                foodName: ''
            },
            typesToAdd: [],
            approvedModal: false,
            tableRatio: false,
            saveAsModal: false
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        Promise.all([this.getDishById(), this.props.getDishTypes(), this.props.getPreparationTypes(), this.props.getFoods(), this.props.getUnitsOfMeasurement()])
            .then(() => {
                this.getNutrients()
            })
            .catch(error => {
                console.log('Promise rejected:', error);
            })
    }

    async componentWillUnmount() {
        store.dispatch({ type: RESET_DISH_DATA });
        store.dispatch({ type: RESET_ALERGEN_DATA })
        store.dispatch({ type: RESET_FODMAP_DATA })
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    addFoodToList = () => {
        let { foodToAdd, dish } = this.state;
        dish.foods.push(foodToAdd)
        const emptyFood = {
            foodId: null,
            foodName: '',
            unitOfMeasurementWithSymbol: {
                unitOfMeasurement: '',
                symbol: ''
            },
            quantity: '',
            gramsByPiece: 0,
            preparationType: ''
        }

        foodToAdd = emptyFood;
        this.setState({ dish: dish, foodToAdd: foodToAdd });
        this.getNutrients()
        this.toggleModal();
    }

    closeDialog = () => {
        this.setState({ deleteFoodModal: !this.state.deleteFoodModal });
    }

    openDialogDelete = (food, index) => {
        this.setState({ deleteFoodModal: !this.state.deleteFoodModal, foodToDelete: food, foodIndex: index });
    }

    deleteFoodFromList = (index) => {
        let { dish } = this.state;

        dish.foods.splice(index, 1);
        if (dish.foods.length === 0) {
            this.setState({ showPieChart: false, showDetailsTable: false, tableRatio: false, showMineralsTable: false })
        } else {
            this.getNutrients()
        }

        this.setState({ dish: dish, deleteFoodModal: !this.state.deleteFoodModal });
    }

    handleChangeDish = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let dish = { ...this.state.dish };
        dish[name] = value;

        this.setState({ dish: dish });
    }

    handleChangeNewDishName = (e) => {
        const target = e.target;
        const value = target.value;

        let newName = { ...this.state.newName };
        newName = value;
        this.setState({ newName: newName });
    }

    handleChangeDishPersons = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let dish = { ...this.state.dish };
        dish[name] = value;

        this.setState({ dish: dish }, () => this.getNutrients());
    }

    getNutrients = () => {
        this.getMacronutrients();
        this.getMicronutrients();
        this.getMinerals();
        this.getAllergenIssue();
        this.getFodmapIssue();
    }

    handleChangeFood = (food) => {
        const { dish } = this.state;
        let foodToAdd = { ...this.state.foodToAdd };

        let foodExists = false;
        if (dish.foods.length > 0 && food.length > 0) {
            dish.foods.forEach(f => {
                if (f.foodName === food[0].name)
                    foodExists = true;
            })
        }
        if (food[0] === undefined) {
            foodToAdd.foodId = null;
            foodToAdd.foodName = '';
            foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement = ''
            foodToAdd.unitOfMeasurementWithSymbol.symbol = ''
            foodToAdd.gramsByPiece = null
        } else {
            foodToAdd.foodId = food[0].id;
            foodToAdd.foodName = food[0].name;
            foodToAdd.gramsByPiece = food[0].gramsByPiece
            if (food[0].defaultUnitOfMeasurementWithSymbol === null) {
                foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement = 'GRAM'
                foodToAdd.unitOfMeasurementWithSymbol.symbol = 'g'
            }
            else {
                foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement = food[0].defaultUnitOfMeasurementWithSymbol.unitOfMeasurement
                foodToAdd.unitOfMeasurementWithSymbol.symbol = food[0].defaultUnitOfMeasurementWithSymbol.symbol
            }
        }
        this.setState({ foodToAdd: foodToAdd, foodExists: foodExists, searchText: '' });
    }

    handleChangeFoods = (index, event) => {
        const dish = { ...this.state.dish };

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "quantity" + index) {
            dish.foods[index].quantity = Number(value);
        }
        else if (name === "unitOfMeasurementWithSymbol" + index) {
            const selectedIndex = target.options.selectedIndex;
            dish.foods[index].unitOfMeasurementWithSymbol.unitOfMeasurement = value;
            dish.foods[index].unitOfMeasurementWithSymbol.symbol = event.target.options[selectedIndex].getAttribute('symbol');
            dish.foods[index].gramsByPiece = null;
        }
        else if (name === "gramsByPiece" + index) {
            dish.foods[index].gramsByPiece = Number(value);
        }
        else if (name === "preparationType" + index) {
            dish.foods[index].preparationType = value;
        }

        this.setState({ dish: dish });
    }

    handleChangeUnit = (event) => {
        const { foodToAdd } = this.state;
        const target = event.target;
        const value = target.value;
        const selectedIndex = target.options.selectedIndex;

        foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement = value;
        foodToAdd.unitOfMeasurementWithSymbol.symbol = event.target.options[selectedIndex].getAttribute('symbol')
        this.setState({ foodToAdd: foodToAdd });
    }

    handleChangeFoodToAdd = (event) => {
        const { foodToAdd, dish } = this.state;
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (foodToAdd.preparationType === "") {
            if (dish.basePreparationType === null) { foodToAdd.preparationType = '' } else { foodToAdd.preparationType = dish.basePreparationType }
        }
        foodToAdd[name] = value;
        this.setState({ foodToAdd: foodToAdd });
    }

    toggleEditable = (index) => {
        this.setState({ editable: !this.state.editable, editableIndex: index }, () => {
            if (this.state.editable === false) {
                this.getNutrients()
            }
        });
    }

    handleChangeType = (types) => {
        let dish = { ...this.state.dish };

        if (types.length <= 0) {
            dish.types = [];
        } else {
            dish.types = types;
        }
        this.setState({ dish: dish });
    }

    async getDishById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/dish/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    let types = []
                    response.data.dish.types.forEach(type => {
                        types.push({
                            type: this.props.t(type),
                            typeEnum: type
                        })
                    })
                    let dish = {
                        ...response.data.dish,
                        types: types
                    }

                    let initialDish = {
                        ...response.data.dish,
                        types: types
                    }

                    this.setState({
                        dish: dish,
                        initialDish: JSON.parse(JSON.stringify(initialDish))
                    });
                    return Promise.resolve();
                })
                .catch(error => {
                    this.toastManager.add(this.props.t("get_dish_by_id_error") + error.response + " " + error.response, {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                    });
                })
        } else {
            return Promise.reject();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { dish } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialDish: this.state.dish });

        let foods = []
        dish.foods.forEach(food => {
            let foodObj = {
                foodId: food.foodId,
                foodName: food.foodName,
                quantity: food.quantity,
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: food.unitOfMeasurementWithSymbol.unitOfMeasurement,
                    symbol: food.unitOfMeasurementWithSymbol.symbol,
                },
                gramsByPiece: food.gramsByPiece,
                preparationType: food.preparationType
            }
            foods.push(foodObj);
        })
        let types = []
        dish.types.forEach(type => types.push(type.typeEnum))

        let dishData = {
            name: dish.name,
            types: types,
            basePreparationType: dish.basePreparationType,
            persons: dish.persons,
            foods: foods
        }


        if (this.props.match.params.id) {
            this.props.updateDish(this.toastManager, this.props.match.params.id, dishData, this.props.history);
        } else {
            this.props.createDish(this.toastManager, dishData, this.props.history);
        }
    }

    saveAsNewDish = () => {
        const { dish, newName } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialDish: this.state.dish });

        let foods = []
        dish.foods.forEach(food => {
            let foodObj = {
                foodId: food.foodId,
                foodName: food.foodName,
                quantity: food.quantity,
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: food.unitOfMeasurementWithSymbol.unitOfMeasurement,
                    symbol: food.unitOfMeasurementWithSymbol.symbol,
                },
                gramsByPiece: food.gramsByPiece,
                preparationType: food.preparationType
            }
            foods.push(foodObj);
        })
        let types = []
        dish.types.forEach(type => types.push(type.typeEnum))

        let dishData = {
            name: newName,
            types: types,
            basePreparationType: dish.basePreparationType,
            persons: dish.persons,
            foods: foods
        }

        this.props.createDish(this.toastManager, dishData, this.props.history);
    }

    getMicronutrients = () => {
        const { dish } = this.state;
        let dishData = []
        this.state.dish.foods.forEach(food => {
            let foodObj = {
                foodId: food.foodId,
                foodName: food.foodName,
                quantity: food.quantity,
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: food.unitOfMeasurementWithSymbol.unitOfMeasurement,
                    symbol: food.unitOfMeasurementWithSymbol.symbol,
                },
                gramsByPiece: food.gramsByPiece,
                preparationType: food.preparationType
            }
            dishData.push(foodObj)
        })
        const request = {
            persons: dish.persons,
            foods: dishData
        }
        Promise.all([this.props.getMicronutrients(request)])
            .then(() => {
                this.setState({ showDetailsTable: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getMinerals = () => {
        const { dish } = this.state;
        let dishData = []
        this.state.dish.foods.forEach(food => {
            let foodObj = {
                foodId: food.foodId,
                foodName: food.foodName,
                quantity: food.quantity,
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: food.unitOfMeasurementWithSymbol.unitOfMeasurement,
                    symbol: food.unitOfMeasurementWithSymbol.symbol,
                },
                gramsByPiece: food.gramsByPiece
            }
            dishData.push(foodObj)

        })
        let request = {
            persons: dish.persons,
            foods: dishData
        }
        Promise.all([this.props.getMinerals(request)])
            .then(() => {
                this.setState({ showMineralsTable: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getMacronutrients = () => {
        const { dish } = this.state;
        let dishData = []
        this.state.dish.foods.forEach(food => {
            let foodObj = {
                foodId: food.foodId,
                foodName: food.foodName,
                quantity: food.quantity,
                unitOfMeasurementWithSymbol: {
                    unitOfMeasurement: food.unitOfMeasurementWithSymbol.unitOfMeasurement,
                    symbol: food.unitOfMeasurementWithSymbol.symbol,
                },
                gramsByPiece: food.gramsByPiece
            }
            dishData.push(foodObj)

        })
        let request = {
            persons: dish.persons,
            foods: dishData
        }
        Promise.all([this.props.getMacronutrients(request)])
            .then(() => {
                this.setState({ showPieChart: true, showMacroTable: true, tableRatio: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getAllergenIssue = () => {
        let { dish } = this.state
        let foodIds = []
        dish.foods.forEach(f => {
            foodIds.push(f.foodId)
        })
        this.props.getAllergenIssue(foodIds, this.toastManager)
    }

    getFodmapIssue = () => {
        let { dish } = this.state
        let foodIds = []
        dish.foods.forEach(f => {
            foodIds.push(f.foodId)
        })
        this.props.getFodmapIssue(foodIds, this.toastManager)
    }

    closeApprovedModalDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleApprovedDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleSaveAsDialog = () => {
        this.setState({ saveAsModal: !this.state.saveAsModal });
    }

    handleSubmitApproved = (e) => {
        e.preventDefault();
        const { dish } = this.state;

        Promise.all([this.props.updateDishApproved(this.props.match.params.id, !dish.approved, this.toastManager)])
            .then(() => {
                this.setState({ approvedModal: false })
                this.getDishById()
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getFoods = (foods) => {
        let sortedFoods = [];
        if (this.state.searchText) {
            let currentFoodsWithSearchText = foods.filter(food => food.name.toLowerCase().startsWith(this.state.searchText.toLowerCase()));
            let currentFoodsWithoutSearchText = foods.filter(food => !food.name.toLowerCase().startsWith(this.state.searchText.toLowerCase()) && food.name.toLowerCase().includes(this.state.searchText.toLowerCase()));
            sortedFoods = currentFoodsWithSearchText.concat(currentFoodsWithoutSearchText);
            return sortedFoods;
        }
        return foods.sort((a, b) => a.name.localeCompare(b.name));
    }

    handleContentDisposition = (response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers["content-disposition"];
        let fileName = contentDisposition.split("filename=")[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    render() {

        const { dishTypes, preparationTypes, foods, macronutrientChartData, macronutrientTableData, macronutrientRatio, macronutrientBarChart, macronutrientBarChartOptions, macronutrientChartOptions, micronutrients, minerals, isLoading, } = this.props.dishData;
        const { dish, initialDish, foodToAdd, editable, modalOpen, editableIndex, foodExists, showPieChart, showDetailsTable, deleteFoodModal, foodToDelete, showMacroTable, showMineralsTable, approvedModal, tableRatio, saveAsModal, newName } = this.state
        const { unitsOfMeasurement } = this.props.foodData;
        const { foodWithAllergens } = this.props.allergenData;
        const { foodWithFodmaps } = this.props.fodmapData;
        const { t } = this.props;

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        let unitOfMeasurementOptions;
        unitOfMeasurementOptions = unitsOfMeasurement.map((unitOfMeasurement, key) => {
            return <option value={unitOfMeasurement.unitOfMeasurement} symbol={unitOfMeasurement.symbol} key={key}>{t(unitOfMeasurement.unitOfMeasurement)}</option>
        })

        let preparationTypeOptions = preparationTypes.map((preparationType, index) => {
            return <option key={index} value={preparationType}>{t(preparationType)}</option>
        })

        let unitOfMeasurementSymbolOptions = unitsOfMeasurement.map((unitOfMeasurement, key) => {
            if (unitOfMeasurement.unitOfMeasurement !== "PIECE") {
                return <option value={unitOfMeasurement.unitOfMeasurement} symbol={unitOfMeasurement.symbol} key={key}>{t(unitOfMeasurement.symbol)}</option>
            } else {
                return <option disabled style={{ background: '#d1d1cf' }} value={unitOfMeasurement.unitOfMeasurement} symbol={unitOfMeasurement.symbol} key={key}>{t(unitOfMeasurement.symbol)}</option>
            }
        })

        const disabledAddFood = foodToAdd.foodName === '' || foodExists || foodToAdd.quantity === 0 || foodToAdd.quantity === '' || foodToAdd.unitOfMeasurement === '' || foodToAdd.preparationType === '';
        const disabledButton = dish.name && dish.persons && dish.types.length > 0 && dish.foods.length > 0
        const modal = <DraggableModal
            isOpen={modalOpen}
            toggle={this.toggleModal}
            size={"sm"}
            title={<strong>{t("add_food")}</strong>}
            body={<div><Col md={12}>
                <Label>{t("food")}</Label>
                <Typeahead
                    selected={foodToAdd.foodName === null ? null : foods.filter(f => f.name === foodToAdd.foodName)}
                    id="foodName"
                    clearButton
                    labelKey={option => `${option.name}`}
                    onChange={this.handleChangeFood}
                    onInputChange={(e) => this.setState({ searchText: e })}
                    options={this.getFoods(foods)}
                    filterBy={(option, props) => {
                        if (props.text.length === 0) {
                            return option;
                        } else if (props.text.length > 0 && option.name.toLowerCase().includes(props.text.toLowerCase() || option.name.toLowerCase().startsWith(props.text.toLowerCase()))) {
                            return option;
                        }
                    }}
                    style={{ maxHeight: '580px' }}
                    placeholder={t("choose_food")}
                    paginationText={t("display_additional")}
                    emptyLabel={t("no_matches_found")}
                    renderMenuItemChildren={(option) => (
                        <div>
                            <strong>{option.name}</strong>
                            <div>
                                {option.defaultUnitOfMeasurementWithSymbol && <small >{t("unit_of_measurement") + `: ${option.defaultUnitOfMeasurementWithSymbol.unitOfMeasurement !== null ? t(option.defaultUnitOfMeasurementWithSymbol.unitOfMeasurement) : ''}`}</small>}
                                {option.defaultUnitOfMeasurementWithSymbol && option.defaultUnitOfMeasurementWithSymbol.unitOfMeasurement !== null ? option.defaultUnitOfMeasurementWithSymbol.unitOfMeasurement === 'PIECE' ?
                                    <small>{" , " + t("grams_by_piece") + `: ${option.gramsByPiece}`}</small> : '' : ''}
                            </div>
                        </div>
                    )}
                />
            </Col>
                <Col md={8}>
                    <Label>{t("unit_of_measurement")}</Label>
                    <Input
                        type="select"
                        name="unitOfMeasurementWithSymbol"
                        value={foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement === '' ? t("select_unit") : foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement}
                        onChange={this.handleChangeUnit}>
                        <option disabled>{t("select_unit")}</option>
                        {unitOfMeasurementOptions}
                    </Input>
                    <FormGroup className={'mw-180'}>
                        {foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement === "PIECE" && <Label>{t("grams_by_piece")}</Label>}
                        {foodToAdd.unitOfMeasurementWithSymbol.unitOfMeasurement === "PIECE" && <Input type="number" name="gramsByPiece" placeholder={t("grams_by_piece")} min={0} value={foodToAdd.gramsByPiece} onChange={this.handleChangeFoodToAdd}></Input>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className={'mw-250'}>
                        <Label>{t("preparation_type")}</Label>
                        <Input
                            type="select"
                            name="preparationType"
                            value={dish.basePreparationType === null && foodToAdd.preparationType === '' ? t("select_preparation_type") : dish.basePreparationType === null && foodToAdd.preparationType !== '' ? foodToAdd.preparationType : dish.basePreparationType !== null && foodToAdd.preparationType === '' ? dish.basePreparationType : foodToAdd.preparationType}
                            onChange={this.handleChangeFoodToAdd}>
                            <option disabled>{t("select_preparation_type")}</option>
                            {preparationTypeOptions}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <Label>{t("quantity")}</Label>
                    <NumberFormat className="form-control" name="quantity" value={foodToAdd.quantity} onChange={this.handleChangeFoodToAdd} allowedDecimalSeparators={[",", "."]} autoComplete="off" allowNegative={false} />
                </Col></div>}
            footer={
                <Button disabled={disabledAddFood} color="success" onClick={this.addFoodToList}>{t("add")}</Button>
            }
            className={this.props.className}
            footerStyle={{ justifyContent: 'flex-end' }}
            modalStyle={{ maxWidth: '520px' }}
        />

        const deleteFoodFromList = <DraggableModal
            isOpen={deleteFoodModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_food")}</strong>}
            body={t("delete_message_food") + " " + foodToDelete.foodName + " ?"}
            footer={
                <div>
                    <Button color="warning" onClick={() => this.closeDialog()}>{t("no")}</Button>{' '}
                    <Button color="success" onClick={() => this.deleteFoodFromList(this.state.foodIndex)}>{t("yes")}</Button>
                </div>
            }
        />

        let message = dish.approved ? t("unapprove_message") + dish.name + "? " + t("reset_message") : t("approve_message") + dish.name + "? " + t("reset_message")
        const changeApprovedModal = <DraggableModal
            isOpen={approvedModal}
            toggle={this.closeApprovedModalDialog}
            size={"sm"}
            title={<strong>{t("change_approved")}</strong>}
            body={message}
            footer={
                <div>
                    <Button color="warning" onClick={this.closeApprovedModalDialog}>{t("no")}</Button>{' '}
                    <Button color="success" onClick={this.handleSubmitApproved}>{t("yes")}</Button>
                </div>
            }
            className={this.props.className}
        />

        const saveModal = <DraggableModal
            isOpen={saveAsModal}
            toggle={this.toggleSaveAsDialog}
            size={"sm"}
            title={<strong>{t("save_new_dish")}</strong>}
            body={
                <Row>
                    <Col md={12}>
                        <FormGroup className={'mw-350'}>
                            <Label>{t("new_dish_name")}</Label>
                            <Input
                                name="newName"
                                type="text"
                                onChange={(e) => this.handleChangeNewDishName(e)}
                                placeholder={t("new_dish_name")}
                                value={newName}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            }
            footer={
                <Button disabled={!newName} color="success" onClick={() => this.saveAsNewDish()}><i className="fas fa-save"></i></Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        return (
            <div className="content content-dish">
                <Prompt when={JSON.stringify(dish) !== JSON.stringify(initialDish)} message={t("warning_message")} />
                {(hasRole('ROLE_STUDENT') && dish.approved) && <Row>
                    <Col md={12}>
                        <Alert color="warning">{t("dish_approved_warning")}</Alert>
                    </Col>
                </Row>}
                <Form onSubmit={this.handleSubmit}>
                    <Row className={'even-height-row row-scroll'}>
                        <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3 w410'}>
                            <DishCard
                                dishTypes={dishTypes}
                                preparationTypes={preparationTypes}
                                dish={dish}
                                handleChange={this.handleChangeDish}
                                handleChangeType={this.handleChangeType}
                                handleChangeDishPersons={this.handleChangeDishPersons}
                                toggleSaveAsDialog={this.toggleSaveAsDialog}
                                initialDish={initialDish}
                                disabledButton={disabledButton}
                                changeApprovedModal={changeApprovedModal}
                                editable={editable}
                                toggleApprovedDialog={this.toggleApprovedDialog}
                                id={this.props.match.params.id ? this.props.match.params.id : ''}
                            />
                            {saveModal}
                        </Col>

                        <Col sm={12} md={12} lg={8} xl={12} className={'col-xxl-8 col-wl-8 col-wxl-6 w820'}>
                            <FoodsCard
                                dish={dish}
                                foods={dish.foods}
                                handleChangeFoods={this.handleChangeFoods}
                                toggleEditable={this.toggleEditable}
                                deleteFoodFromList={this.openDialogDelete}
                                toggleModal={this.toggleModal}
                                getMacronutrients={this.getMacronutrients}
                                getMicronutrients={this.getMicronutrients}
                                editableIndex={editableIndex}
                                approved={dish.approved}
                                unitOfMeasurementSymbolOptions={unitOfMeasurementSymbolOptions}
                                preparationTypeOptions={preparationTypeOptions}
                                foodWithAllergens={foodWithAllergens}
                                foodWithFodmaps={foodWithFodmaps} />
                            {modal}
                            {deleteFoodFromList}
                        </Col>
                    </Row>
                    <Row className={'even-height-row row-scroll'}>
                        <Col lg={6} xl={7} className={'col-xxl-7 col-wxl-6 w820'}>
                            {showPieChart && showMacroTable &&
                                <MacronutrientsDishCard data={macronutrientChartData} options={macronutrientChartOptions} width={160} height={90} legend={{ position: 'right' }} macronutrients={macronutrientTableData} dishId={this.props.match.params.id} handleContentDisposition={this.handleContentDisposition} />}
                        </Col>
                        <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-5 col-wxl-3 w410'}>
                            {tableRatio &&
                                <MacronutrientsChartAndRatioTable data={macronutrientBarChart} options={macronutrientBarChartOptions} height={230} tableData={macronutrientRatio} />}
                        </Col>
                    </Row>
                    <Row className={'row-scroll'}>
                        <Col sm={12} md={6} lg={4} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-3 w410'}>
                            {showDetailsTable && <MicronutrientsTableCard data={micronutrients} />}
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-3 w410'}>
                            {showMineralsTable && <MineralsTableCard data={minerals} />}
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

Dish.propTypes = {
    dishData: PropTypes.object.isRequired,
    foodData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    dishData: state.dishData,
    foodData: state.foodData,
    allergenData: state.allergenData,
    fodmapData: state.fodmapData
})

const mapActionsToProps = { createDish, updateDish, updateDishApproved, getDishTypes, getPreparationTypes, getFoods, getMacronutrients, getMicronutrients, getMinerals, getUnitsOfMeasurement, getAllergenIssue, getFodmapIssue }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Dish)));
