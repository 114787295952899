import React from "react";
import { Prompt } from "react-router-dom";
import PropTypes from 'prop-types';
import { Alert, Card, Form, FormGroup, Row, Col, Button, Label, Input, CardHeader, CardTitle, CardBody, UncontrolledTooltip } from "reactstrap";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import NutrientIntervalsCard from "../../components/diet/NutrientIntervalsCard"
import DraggableModal from '../../components/modal/DraggableModal'
import NumberFormat from 'react-number-format';
// Redux
import { connect } from 'react-redux';
import { createDiet, updateDiet, updateDietApproved, getNutrientTypes } from '../../redux/actions/dietActions';
import { hasRole } from '../../util/hasRole';
// Util
import { checkAuth } from '../../util/checkAuth';

class Diet extends React.Component {

    emptyDiet = {
        name: '',
        description: '',
        nutrientIntervals: [],
        approved: false
    }

    constructor(props) {
        super(props);
        this.state = {
            diet: this.emptyDiet,
            initialDiet: this.emptyDiet,
            editableIndexes: [],
            deleteIndexes: [],
            isLoading: false,
            modalOpen: false,
            deleteNutrientIntervalModalOpen: false,
            nutrientTypeExists: false,
            nutrientIntervalToAdd: {
                id: null,
                nutrientTypeUnit: '',
                nutrientType: null,
                fromValue: 0,
                toValue: 0,
            },
            nutrientIntervalToDelete: {
                nutrientType: ''
            },
            approvedModal: false,
            nutrientInterval: {},
            saveAsModal: false,
            newName: '',
            nutrientIntervalToAddCarb: {
                id: null,
                nutrientTypeUnit: '',
                nutrientType: null,
                fromValue: 0,
                toValue: 0
            },
            nutrientIntervalToAddFat: {
                id: null,
                nutrientTypeUnit: '',
                nutrientType: null,
                fromValue: 0,
                toValue: 0
            },
            nutrientIntervalToAddProtein: {
                id: null,
                nutrientTypeUnit: '',
                nutrientType: null,
                fromValue: 0,
                toValue: 0
            },
            editable: false
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
        await checkAuth();
        await this.getDietById();
        await this.props.getNutrientTypes();
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let diet = { ...this.state.diet };
        diet[name] = value;

        this.setState({ diet: diet });
    }

    handleChangeNutrientType = (e) => {
        const { diet, nutrientIntervalToAdd } = this.state;

        let nutrientTypeExists = false;
        diet.nutrientIntervals.forEach(ni => {
            if (ni.nutrientType === e.target.value)
                nutrientTypeExists = true;
        })
        if (e.target.value === "KILOCALORIES") {
            this.setState({ addNewField: true })
        }
        else {
            this.setState({ addNewField: false })
        }

        const selectedIndex = e.target.options.selectedIndex;
        nutrientIntervalToAdd[e.target.name] = e.target.value;
        nutrientIntervalToAdd.nutrientTypeUnit = e.target.options[selectedIndex].getAttribute('unit')
        this.setState({ nutrientIntervalToAdd: nutrientIntervalToAdd, nutrientTypeExists: nutrientTypeExists });
    }

    handleChangeFromAndToModal = (e) => {
        let { nutrientIntervalToAdd } = this.state;
        nutrientIntervalToAdd[e.target.name] = e.target.value;
        this.setState({ nutrientIntervalToAdd: nutrientIntervalToAdd })
    }

    handleChangeFromAndToTotalCarb = (e) => {
        let { nutrientIntervalToAddCarb } = this.state;

        nutrientIntervalToAddCarb[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddCarb[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddCarb.nutrientType = "TOTAL_CARBOHYDRATES"
        nutrientIntervalToAddCarb.nutrientTypeUnit = "%"
        this.setState({ nutrientIntervalToAddCarb: nutrientIntervalToAddCarb })
    }

    handleChangeFromAndToTotalFat = (e) => {
        let { nutrientIntervalToAddFat } = this.state;

        nutrientIntervalToAddFat[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddFat[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddFat.nutrientType = "TOTAL_FAT"
        nutrientIntervalToAddFat.nutrientTypeUnit = "%"

        this.setState({ nutrientIntervalToAddFat: nutrientIntervalToAddFat })
    }

    handleChangeFromAndToTotalProtein = (e) => {
        let { nutrientIntervalToAddProtein } = this.state;

        nutrientIntervalToAddProtein[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddProtein[e.target.name] = Number(e.target.value);
        nutrientIntervalToAddProtein.nutrientType = "TOTAL_PROTEIN"
        nutrientIntervalToAddProtein.nutrientTypeUnit = "%"

        this.setState({ nutrientIntervalToAddProtein: nutrientIntervalToAddProtein })
    }

    handleChangeFromAndToTable = (index, event) => {
        const { diet } = this.state;

        if (event.target.name === "fromValue" + index) {
            diet.nutrientIntervals[index].fromValue = Number(event.target.value);
        } else if (event.target.name === 'toValue' + index) {
            diet.nutrientIntervals[index].toValue = Number(event.target.value);
        }

        this.setState({ diet: diet });
    }

    handleChangeNewDietName = (e) => {
        const target = e.target;
        const value = target.value;

        let newName = { ...this.state.newName };
        newName = value;
        this.setState({ newName: newName });
    }

    addNutrientInterval = () => {
        let { nutrientIntervalToAdd, diet, nutrientIntervalToAddCarb, nutrientIntervalToAddFat, nutrientIntervalToAddProtein } = this.state;

        if (nutrientIntervalToAdd.nutrientType === "KILOCALORIES") {
            diet.nutrientIntervals.push(nutrientIntervalToAdd)
            diet.nutrientIntervals.push(nutrientIntervalToAddCarb)
            diet.nutrientIntervals.push(nutrientIntervalToAddFat)
            diet.nutrientIntervals.push(nutrientIntervalToAddProtein)
        } else {
            diet.nutrientIntervals.push(nutrientIntervalToAdd)
        }

        const emptyInterval = {
            fromValue: 0,
            toValue: 0,
            nutrientType: null,
            nutrientTypeUnit: ''
        }

        nutrientIntervalToAdd = emptyInterval
        nutrientIntervalToAddCarb = emptyInterval
        nutrientIntervalToAddFat = emptyInterval
        nutrientIntervalToAddProtein = emptyInterval

        this.setState({ diet: diet, nutrientIntervalToAdd: nutrientIntervalToAdd, addNewField: false });
        this.toggleModal();
    }

    deleteNutrientInterval = () => {
        let { diet, deleteIndexes } = this.state;

        for (let i = 0; i < deleteIndexes.length; i++) {
            diet.nutrientIntervals.splice(deleteIndexes[i] - i, 1);
        }

        this.setState({ diet: diet, deleteNutrientIntervalModalOpen: false });
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    openDialogDelete = (nutrientInterval, index) => {
        let { deleteIndexes } = this.state

        deleteIndexes.push(index)
        if (nutrientInterval.nutrientType === "KILOCALORIES") {
            deleteIndexes.push(index + 1)
            deleteIndexes.push(index + 2)
            deleteIndexes.push(index + 3)
        }

        this.setState({ deleteNutrientIntervalModalOpen: !this.state.deleteNutrientIntervalModalOpen, nutrientIntervalToDelete: nutrientInterval, deleteIndexes: deleteIndexes });
    }

    closeDialog = () => {
        this.setState({ deleteNutrientIntervalModalOpen: !this.state.deleteNutrientIntervalModalOpen, deleteIndexes: [] });
    }

    toggleEditable = (indexes) => {
        let { editableIndexes } = this.state

        editableIndexes = indexes;

        this.setState({ editableIndexes: editableIndexes, editable: !this.state.editable })
    }

    closeApprovedModalDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleApprovedDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleSaveAsDialog = () => {
        this.setState({ saveAsModal: !this.state.saveAsModal });
    }

    handleSubmitApproved = (e) => {
        e.preventDefault();
        const { diet } = this.state;

        Promise.all([this.props.updateDietApproved(this.props.match.params.id, !diet.approved, this.toastManager)])
            .then(() => {
                this.setState({ approvedModal: false })
                this.getDietById()
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    async getDietById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/diet/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.setState({
                        diet: response.data.diet,
                        initialDiet: JSON.parse(JSON.stringify(response.data.diet)),
                        isLoading: false
                    });
                })
                .catch(error => {
                        this.toastManager.add(this.props.t("get_diet_by_id_message"), {
                            appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                        });
                })
        } else {
            this.setState({ isLoading: false })
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();

        const { diet } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialDiet: diet });

        if (this.props.match.params.id) {
            this.props.updateDiet(this.toastManager, this.props.match.params.id, diet, this.props.history);
        } else {
            this.props.createDiet(this.toastManager, diet, this.props.history);
        }
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    checkNutrientIntervals = () => {
        const { nutrientIntervalToAdd, nutrientIntervalToAddCarb, nutrientIntervalToAddFat, nutrientIntervalToAddProtein } = this.state;
        let { fromValue, toValue } = nutrientIntervalToAdd

        if (nutrientIntervalToAdd.nutrientType === "KILOCALORIES") {
            return fromValue < 0 || (fromValue * 1 === toValue * 1) || fromValue === '' || toValue === '' || nutrientIntervalToAddCarb.toValue === 0 || nutrientIntervalToAddFat.toValue === 0 || nutrientIntervalToAddProtein.toValue === 0
        }
        else {
            if (fromValue * 1 > toValue * 1) {
                return fromValue < 0 || (fromValue * 1 === toValue * 1) || fromValue === '' || toValue === '' || Number(toValue) !== 0 || (toValue * 1 > fromValue * 1)
            }
            else {
                return fromValue * 1 === toValue * 1
            }
        }
    }

    saveAsNewDiet = () => {

        const { diet, newName } = this.state;
        // To prevent router prompt from opening on submit
        this.setState({ initialDiet: diet });

        const newDiet = {
            ...diet,
            name: newName
        }
        this.props.createDiet(this.toastManager, newDiet, this.props.history)
    }


    render() {

        const { diet, initialDiet, editableIndexes, isLoading, modalOpen, deleteNutrientIntervalModalOpen, nutrientIntervalToAdd, nutrientIntervalToDelete, nutrientTypeExists, approvedModal, saveAsModal, newName, nutrientIntervalToAddCarb, nutrientIntervalToAddFat, nutrientIntervalToAddProtein, editable } = this.state;
        const { nutrientTypes } = this.props.dietData
        const { t } = this.props;

        const disabledButton = diet.name && diet.nutrientIntervals.length > 0;
        const disabledAddNutrientInterval = nutrientIntervalToAdd.nutrientType === null || nutrientTypeExists || this.checkNutrientIntervals()

        let nutrientTypeOptions = nutrientTypes.map((nutrientType, index) => {
            if (nutrientType.nutrientType !== "TOTAL_CARBOHYDRATES" && nutrientType.nutrientType !== "TOTAL_FAT" && nutrientType.nutrientType !== "TOTAL_PROTEIN") {
                return <option key={index} unit={nutrientType.unit} value={nutrientType.nutrientType}>{t(nutrientType.nutrientType)}</option>
            }
            return null
        })

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        const withValueCap = (inputObj) => {
            const { value } = inputObj;
            if (value <= 100) return true;
            return false;
        };

        const addNewRowsInModal = <div>
            <hr />
            <Row>
                <Col><Label>{t("TOTAL_CARBOHYDRATES")}</Label>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Label>{t("from")}</Label>
                    <NumberFormat className="form-control" name="fromValue" onChange={this.handleChangeFromAndToTotalCarb} value={nutrientIntervalToAddCarb.fromValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("to")}</Label>
                    <NumberFormat className="form-control" name="toValue" onChange={this.handleChangeFromAndToTotalCarb} value={nutrientIntervalToAddCarb.toValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("unit")}</Label>
                    <Input name="unit" type="text" disabled value={'%'} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col><Label>{t("TOTAL_FAT")}</Label>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Label>{t("from")}</Label>
                    <NumberFormat className="form-control" name="fromValue" onChange={this.handleChangeFromAndToTotalFat} value={nutrientIntervalToAddFat.fromValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("to")}</Label>
                    <NumberFormat className="form-control" name="toValue" onChange={this.handleChangeFromAndToTotalFat} value={nutrientIntervalToAddFat.toValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("unit")}</Label>
                    <Input name="unit" type="text" disabled value={'%'} />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col><Label>{t("TOTAL_PROTEIN")}</Label>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Label>{t("from")}</Label>
                    <NumberFormat className="form-control" name="fromValue" onChange={this.handleChangeFromAndToTotalProtein} value={nutrientIntervalToAddProtein.fromValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("to")}</Label>
                    <NumberFormat className="form-control" name="toValue" onChange={this.handleChangeFromAndToTotalProtein} value={nutrientIntervalToAddProtein.toValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                </Col>
                <Col md={4}>
                    <Label>{t("unit")}</Label>
                    <Input name="unit" type="text" disabled value={'%'} />
                </Col>
            </Row></div>

        const modal = <DraggableModal
            isOpen={modalOpen}
            toggle={this.toggleModal}
            size={"sm"}
            title={<strong>{t("add_diet_details")}</strong>}
            body={<div><Row>
                <Col md={10}>
                    <Label>{t("nutrient_type")}</Label>
                    <Input
                        type="select"
                        name="nutrientType"
                        value={nutrientIntervalToAdd.nutrientType === null ? t("select_nutrient_type") : nutrientIntervalToAdd.nutrientType}
                        onChange={(e) => this.handleChangeNutrientType(e)}>
                        <option disabled>{t("select_nutrient_type")}</option>
                        {nutrientTypeOptions}
                    </Input>
                </Col>
            </Row>
                <Row>
                    <Col md={4}>
                        <Label>{t("from")}</Label>
                        <NumberFormat className="form-control" name="fromValue" onChange={this.handleChangeFromAndToModal} value={nutrientIntervalToAdd.fromValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} />
                    </Col>
                    <Col md={4}>
                        <span style={{ cursor: "pointer" }} id={"target1"}>
                            <Label>{t("to")}</Label>
                            <UncontrolledTooltip style={{ fontSize: '14px', color: 'red' }} placement="bottom" target={"target1"}>
                                {t("info_toValue_input")}
                            </UncontrolledTooltip>
                            <NumberFormat className="form-control" name="toValue" onChange={this.handleChangeFromAndToModal} value={nutrientIntervalToAdd.toValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} />
                        </span>

                    </Col>
                    <Col md={4}>
                        <Label>{t("unit")}</Label>
                        <Input
                            name="unit"
                            type="text"
                            disabled
                            value={nutrientIntervalToAdd.nutrientTypeUnit || ''}
                        />
                    </Col>
                </Row>
                {this.state.addNewField && addNewRowsInModal}

            </div>}
            footer={
                <Button disabled={disabledAddNutrientInterval} color="success" onClick={this.addNutrientInterval}>{t("add")}</Button>
            }
            className={this.props.className}
            footerStyle={{ justifyContent: 'flex-end' }}
            modalStyle={{ maxWidth: '400px' }}
        />

        const deleteNutrientIntervalModal = <DraggableModal
            isOpen={deleteNutrientIntervalModalOpen}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_food")}</strong>}
            body={t("delete_message_nutrient_interval") + " " + t(nutrientIntervalToDelete.nutrientType) + " ?"}
            footer={
                <div>
                    <Button color="warning" onClick={() => this.closeDialog()}>{t("no")}</Button>{' '}
                    <Button color="success" onClick={() => this.deleteNutrientInterval()}>{t("yes")}</Button>
                </div>
            }
        />

        let message = diet.approved ? t("unapprove_message") + diet.name + "? " + t("reset_message") : t("approve_message") + diet.name + "? " + t("reset_message")
        const changeApprovedModal = <DraggableModal
            isOpen={approvedModal}
            toggle={this.closeApprovedModalDialog}
            size={"sm"}
            title={<strong>{t("change_approved")}</strong>}
            body={message}
            footer={
                <div>
                    <Button color="warning" onClick={this.closeApprovedModalDialog}>{t("no")}</Button>{' '}
                    <Button color="success" onClick={this.handleSubmitApproved}>{t("yes")}</Button>
                </div>
            }
            className={this.props.className}
        />


        const saveModal = <DraggableModal
            isOpen={saveAsModal}
            toggle={this.toggleSaveAsDialog}
            size={"sm"}
            title={<strong>{t("save_new_diet")}</strong>}
            body={
                <Row>
                    <Col md={12}>
                        <FormGroup className={'mw-350'}>
                            <Label>{t("new_diet_name")}</Label>
                            <Input
                                name="newName"
                                type="text"
                                onChange={this.handleChangeNewDietName}
                                placeholder={t("new_diet_name")}
                                value={newName}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            }
            footer={
                <Button disabled={!newName} color="success" onClick={() => this.saveAsNewDiet()}><i className="fas fa-save"></i></Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        return (
            <div className="content content-diet">
                <Prompt when={JSON.stringify(diet) !== JSON.stringify(initialDiet)} message={t("warning_message")} />
                {(hasRole('ROLE_STUDENT') && diet.approved) && <Row>
                    <Col md={8}>
                        <Alert color="warning">{t("diet_approved_warning")}</Alert>
                    </Col>
                </Row>}
                <Form onSubmit={this.handleSubmit}>
                    <Row className={'even-height-row'}>
                        <Col sm={8} md={6} lg={6} xl={6} className={'col-xxl-5 col-wxl-4'}>
                            <Card className={'even-height-card'}>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("diet")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={diet.name}
                                            autoComplete="off"
                                        />
                                    </FormGroup>

                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("description")}</Label>
                                        <Input
                                            name="description"
                                            type="textarea"
                                            rows="4"
                                            onChange={this.handleChange}
                                            placeholder={t("description")}
                                            value={diet.description}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <br />
                                    <Row>
                                        <Col xs={12}>
                                            {this.props.match.params.id ?
                                                <Button disabled={JSON.stringify(diet) === JSON.stringify(initialDiet) || editable} type="submit" color="success"> {t("update")}</Button> :
                                                <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>}
                                            {(hasRole('ROLE_SUPER_ADMIN') && this.props.match.params.id) &&
                                                (diet.approved ? <Button type="button" color="warning" onClick={this.toggleApprovedDialog}>{t("unapprove")}</Button>
                                                    : <Button type="button" color="info" onClick={this.toggleApprovedDialog}>{t("approve")}</Button>)}
                                            <Button type="button" color="info" onClick={this.toggleSaveAsDialog}> {t("save_as")}</Button>
                                        </Col></Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col sm={10} md={8} lg={8} xl={10} className={'col-xxl-7 col-wxl-6'}>
                            <NutrientIntervalsCard
                                diet={diet}
                                handleChange={this.handleChangeFromAndToTable}
                                toggleEditable={this.toggleEditable}
                                openDialogDelete={this.openDialogDelete}
                                toggleModal={this.toggleModal}
                                editableIndexes={editableIndexes}
                            />
                            {modal}
                            {deleteNutrientIntervalModal}
                        </Col>
                        {changeApprovedModal}
                        {saveModal}
                    </Row>
                </Form >
            </div >
        );
    }
}

Diet.propTypes = {
    dietData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    dietData: state.dietData
})

const mapActionsToProps = { createDiet, updateDiet, updateDietApproved, getNutrientTypes }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Diet)))