import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/black-dashboard-react.scss";
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/nucleo-icons.css";
import "assets/css/app.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "assets/css/LoginForm.css";
import './i18n';
import 'react-flags-select/css/react-flags-select.css';
import "assets/scss/override/panel.scss";
import 'react-data-table-component-extensions/dist/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import App from './App.js'

ReactDOM.render(<App />, document.getElementById("root"));