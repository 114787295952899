import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Card, Form, FormGroup, Row, Col, Button, Label, Input, CardHeader, CardBody, CardTitle } from "reactstrap";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { createAllergen, updateAllergen } from '../../redux/actions/allergenActions';
// Util
import { checkAuth } from '../../util/checkAuth';
import { Typeahead } from "react-bootstrap-typeahead";

class Allergen extends React.Component {

    emptyAllergen = {
        name: '',
        description: '',
        foods: []
    }

    constructor(props) {
        super(props);
        this.state = {
            allergen: this.emptyAllergen,
            initialAllergen: this.emptyAllergen,
            isLoading: true
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        Promise.all([await checkAuth(), this.getAllegrenById()])
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let allergen = { ...this.state.allergen };
        allergen[name] = value;

        this.setState({ allergen: allergen });
    }

    handleChangeFoods = (foods) => {
        let allergen = { ...this.state.allergen };

        if (foods.length <= 0) {
            allergen.foods = [];
        } else {
            allergen.foods = foods;
        }
        this.setState({ allergen: allergen });
    }

    async getAllegrenById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/allergen/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.setState({
                        allergen: response.data.allergen,
                        initialAllergen: JSON.parse(JSON.stringify(response.data.allergen)),
                        isLoading: false
                    });
                })
                .catch(() => {
                    this.toastManager.add(this.props.t("get_allergen_by_id"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                    });
                })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { allergen } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialAllergen: allergen });

        let foods = []
        allergen.foods.forEach(food => {
            let foodObj = {
                id: food.id,
                name: food.name,
            }
            foods.push(foodObj);
        })

        let allergenData = {
            name: allergen.name,
            description: allergen.description,
            foods: foods
        }

        Promise.all([this.props.match.params.id ?
            this.props.updateAllergen(this.toastManager, this.props.match.params.id, allergenData) : this.props.createAllergen(this.toastManager, allergenData)])
            .then(() => {
                this.props.history.push('/admin/allergenList')
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    render() {

        const { allergen, initialAllergen, isLoading } = this.state;
        const { foods } = this.props.dishData;
        const { t } = this.props;

        const disabledButton = allergen.name && allergen.description && allergen.foods.length > 0

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="content">
                <Prompt when={JSON.stringify(allergen) !== JSON.stringify(initialAllergen)} message={t("warning_message")} />
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={8} md={8} lg={8} xl={8} className={'col-xxl-6 col-wxl-4'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("allergen")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={allergen.name}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("description")}</Label>
                                        <Input
                                            name="description"
                                            type="textarea"
                                            rows="4"
                                            onChange={this.handleChange}
                                            placeholder={t("description")}
                                            value={allergen.description}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-450'}>
                                        <Label>{t("foods")}</Label>
                                        <Typeahead
                                            selected={allergen.foods}
                                            id="foods"
                                            clearButton
                                            multiple
                                            labelKey={option => `${option.name}`}
                                            onChange={(e) => this.handleChangeFoods(e)}
                                            options={foods}
                                            placeholder={t("choose_foods")}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                    <strong>{option.name}</strong>
                                                </div>
                                            )}
                                            paginationText={t("display_additional")}
                                            emptyLabel={t("no_matches_found")}
                                        />
                                    </FormGroup>
                                </CardBody>
                            </Card>
                            {
                                this.props.match.params.id ?
                                    <Button disabled={(JSON.stringify(allergen) === JSON.stringify(initialAllergen))} type="submit" color="success"> {t("update")}</Button> :
                                    <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>
                            }
                        </Col>
                    </Row>

                </Form >
            </div >
        );
    }
}

Allergen.propTypes = {
    allergenData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    allergenData: state.allergenData,
    dishData: state.dishData
})

const mapActionsToProps = { createAllergen, updateAllergen }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Allergen)))