import React from "react"
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, Row, Col, Button, Input, FormGroup, Label } from "reactstrap"
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import ClipLoader from "react-spinners/ClipLoader"
import { withToastManager } from 'react-toast-notifications'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import DraggableModal from '../../components/modal/DraggableModal'
import { Typeahead } from 'react-bootstrap-typeahead'
// Redux
import { connect } from 'react-redux'
import { getMeals, deleteMeal, searchMeal, copySelectedMeals, copyAllMeals } from '../../redux/actions/mealActions'
import { getCompaniesForUser } from '../../redux/actions/companyActions'
import store from '../../redux/store'
import { SET_COPY_MEAL_LOADING } from '../../redux/types'
// Util
import { hasRole } from "util/hasRole"
import { checkAuth } from '../../util/checkAuth'

class MealList extends React.Component {

    emptyDiet = {
        name: '',
        fatFrom: 0,
        fatTo: 0,
        carbohydrateFrom: 0,
        carbohydrateTo: 0,
        proteinFrom: 0,
        proteinTo: 0,
        fiberFrom: 0,
        fiberTo: 0
    }

    constructor(props) {
        super(props);
        this.state = {
            meal: null,
            deleteMealModel: false,
            isDescriptionModalOpen: false,
            mealToDelete: this.emptyDiet,
            mealSearch: "",
            isLoading: true,
            company: null,
            mealIds: []
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        this.props.getMeals(0, 10);
        hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
        this.setState({ isLoading: false })
    }

    handleChange = (id) => {
        let { mealIds } = this.state
        if (!mealIds.includes(id)) {
            mealIds.push(id)
        } else {
            mealIds.splice(mealIds.indexOf(id), 1)
        }
        this.setState({ mealIds: mealIds })
    }

    onChangePage = (page, size) => {
        if (this.state.mealSearch !== "") {
            this.props.searchMeal(page - 1, this.props.mealData.size, this.state.mealSearch)
        } else {
            this.props.getMeals(page - 1, this.props.mealData.size)
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if (this.state.mealSearch !== "") {
            this.props.searchMeal(currentPage - 1, currentRowsPerPage, this.state.mealSearch)
        } else {
            this.props.getMeals(currentPage - 1, currentRowsPerPage)
        }
    }

    openDeleteDialog = (row) => {
        this.setState({ deleteMealModel: !this.state.deleteMealModel, mealToDelete: row, })

    }

    closeDialog = () => {
        this.setState({ deleteMealModel: !this.state.deleteMealModel, })
    }

    deleteDiet = () => {
        this.props.deleteMeal(this.toastManager, this.state.mealToDelete.id)
        this.setState({ deleteMealModel: false })
    }

    handleMealSearch = (event) => {
        this.setState({ mealSearch: event.target.value })
    }

    /* shouldHideEditButton = (row) => {
        if ((hasRole('ROLE_ADMIN') || hasRole('ROLE_STUDENT')) && row.approved) {
            return true;
        }
        return false;
    } */

    copyDialog = (copyAllDialog) => {
        if (copyAllDialog === false) {
            store.dispatch({ type: SET_COPY_MEAL_LOADING, payload: { copyAllDialog: true, copyDialog: true } })
        }
        else if (copyAllDialog === undefined) {
            store.dispatch({
                type: SET_COPY_MEAL_LOADING, payload: { copyAllDialog: false, copyDialog: true }
            })
        }
    }

    closeCopyDialog = () => {
        store.dispatch({
            type: SET_COPY_MEAL_LOADING,
            payload: {
                copyDialog: false
            }
        })
        this.setState({ company: null })
    }

    handleChangeCompany = (companyEntry) => {
        let company = { ...this.state.company };
        if (companyEntry.length > 0) {
            company = companyEntry[0]
        } else { company = null }
        this.setState({ company: company })
    }

    copyData = () => {
        const { mealIds, company } = this.state
        const requestData = {
            ids: mealIds,
            companyId: company.id
        }
        this.props.copySelectedMeals(requestData, this.toastManager)
    }

    render() {
        const { meals, totalElements, copyAllDialog, copyDialog, copyLoading } = this.props.mealData;
        const { companiesForUser } = this.props.companyData
        const { deleteMealModel, mealToDelete, isLoading, company, mealIds } = this.state;
        const { t } = this.props

        const deleteModal = <DraggableModal
            isOpen={deleteMealModel}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_meal")}</strong>}
            body={<div>
                {t("delete_meal_message")}{' '}<strong>{mealToDelete.name}</strong>{' ?'}
            </div>}
            footer={
                <Button color="success" onClick={this.deleteDiet}>{t("delete")}</Button>
            }
        />

        const copyToOtherCompany = <DraggableModal
            isOpen={copyDialog}
            toggle={this.closeCopyDialog}
            size={"sm"}
            title={<strong>{t("copy_to_another_company")}</strong>}
            body={<div>
                <FormGroup>
                    <Typeahead
                        selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
                        id="company"
                        clearButton
                        labelKey="name"
                        onChange={this.handleChangeCompany}
                        options={companiesForUser}
                        placeholder={t("select_company")}
                        paginationText={t("display_additional")}
                        emptyLabel={t("no_matches_found")}
                    />
                </FormGroup>
            </div>}
            footer={
                <Button style={{ width: '82.64px', height: '40px' }} color="success" disabled={company === null} onClick={copyAllDialog ? () => this.props.copyAllMeals(company.id, this.toastManager) : () => this.copyData()}>{copyLoading ? <ClipLoader
                    size={20}
                    color={"#e14eca"}
                    loading={copyLoading}
                /> : t("copy")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        const mealColumns = [
            {
                name: t("name"),
                selector: "name",
                sortable: true,
                width: "30%"
            },
            {
                name: t("description"),
                selector: "description",
                sortable: true,
                width: "30%"
            }
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "10%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const createdAt = {
            name: t("created_at"),
            selector: "createdAt",
            sortable: true,
            width: hasRole("ROLE_SUPER_ADMIN") ? "8%" : "15%",
            cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
        }

        const updatedAt = {
            name: t("updated_at"),
            selector: "updatedAt",
            sortable: true,
            width: hasRole("ROLE_SUPER_ADMIN") ? "8%" : "10%",
            cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
        }

        const actions = {
            name: t("actions"),
            width: hasRole("ROLE_SUPER_ADMIN") ? "9%" : "10%",
            center: 'true',
            cell: row => <span>
                <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/meal/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDeleteDialog(row)}><i className="tim-icons icon-simple-remove"></i></Button>
            </span>
        }

        const copy = {
            name: t("copy"),
            width: "5%",
            cell: row =>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="select" id="select" onChange={() => this.handleChange(row.id)} style={{ position: 'relative' }} checked={mealIds.some(item => item === row.id)} />
                        <span className="form-check-sign">
                            <span className="check" />
                        </span>
                    </Label>
                </FormGroup>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            mealColumns.push(createdBy)
            mealColumns.push(createdAt)
            mealColumns.push(updatedAt)
            mealColumns.push(actions)
            mealColumns.push(copy)
        } else {
            mealColumns.push(createdAt)
            mealColumns.push(updatedAt)
            mealColumns.push(actions)
        }

        return (
            <div className="content content-meal">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col sm={12} md={4}>
                                    </Col>
                                    <Col sm={7} md={3}>
                                        <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_meals")} autoComplete="off" name="mealSearch" id="mealSearch" value={this.state.mealSearch || ''} onChange={this.handleMealSearch}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.state.dietSearch === "" ? this.props.getMeals(0, 10) : this.props.searchMeal(0, this.props.mealData.size, this.state.mealSearch)
                                                }
                                            }} />
                                    </Col>
                                    <Col xs={1} sm={1}>
                                        <Button color="success" size="sm" type="button" onClick={this.state.dietSearch === "" ? () => this.props.getMeals(0, 10) : () => this.props.searchMeal(0, this.props.mealData.size, this.state.mealSearch)}><i className="fas fa-search"></i></Button>
                                    </Col>
                                    <Col sm={5} md={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/meal" color="success">{t("create_meal")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={mealColumns}
                                        data={meals}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                            {deleteModal}
                            {copyToOtherCompany}
                        </Card>
                        <div>
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={mealIds.length === 0} onClick={() => this.copyDialog()}><i className="far fa-copy"></i> {t("copy_selected_to_another_company")}</Button>}
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={meals.length === 0} onClick={() => this.copyDialog(copyAllDialog)}><i className="far fa-copy"></i> {t("copy_all_to_another_company")}</Button>}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

MealList.propTypes = {
    mealData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    mealData: state.mealData,
    companyData: state.companyData
})

const mapActionsToProps = { getMeals, deleteMeal, searchMeal, getCompaniesForUser, copySelectedMeals, copyAllMeals }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(MealList)));