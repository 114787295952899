import React from "react";
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import { getCompanies, deleteCompany } from '../../redux/actions/companyActions';
// Util
import { checkAuth } from '../../util/checkAuth';

class CompanyList extends React.Component {

    emptyCompany = {
        id: null,
        name: '',
        email: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            deleteCompanyModal: false,
            companyToDelete: this.emptyCompany,
            isLoading: true,
            imageModal: false,
            imageBase64: ''
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        this.props.getCompanies(0, 10);
        this.setState({ isLoading: false });
    }

    onChangePage = (page, size) => {
        this.props.getCompanies(page - 1, this.props.companyData.size);
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        this.props.getCompanies(currentPage - 1, currentRowsPerPage);
    }

    openDeleteDialog = (row) => {
        this.setState({ deleteCompanyModal: !this.state.deleteCompanyModal, companyToDelete: row, });
    }

    openImageDialog = (row) => {
        this.setState({ imageModal: !this.state.imageModal, imageBase64: row });
    }

    closeImageDialog = () => {
        this.setState({ imageModal: !this.state.imageModal });
    }

    closeDialog = () => {
        this.setState({ deleteCompanyModal: !this.state.deleteCompanyModal });
    }

    deleteCompany = () => {
        this.props.deleteCompany(this.toastManager, this.state.companyToDelete.id);
        this.setState({ deleteCompanyModal: false });
    }

    render() {
        const { companies, totalElements } = this.props.companyData;
        const { isLoading, deleteCompanyModal, companyToDelete, imageModal, imageBase64 } = this.state;
        const { t } = this.props;

        const companyColumns = [
            {
                name: t("name"),
                selector: "name",
                sortable: true,
                width: "16%"
            },
            {
                name: t("email"),
                selector: "email",
                width: "17%",
                sortable: true
            },
            {
                name: t("responsible_person"),
                selector: "responsiblePerson",
                width: "12%",
                sortable: true
            },
            {
                name: t("responsible_persons_contact"),
                selector: "responsiblePersonsContact",
                width: "12%",
                sortable: true
            },
            {
                name: t("watermark"),
                selector: "watermarkEnabled",
                width: "5%",
                cell: (row) => <span>
                    {row.watermarkEnabled ? <i style={{ color: "green", fontSize: '20px' }} className="fas fa-check-circle"></i> : <i style={{ color: "red", fontSize: '20px' }} className="far fa-times-circle"></i>}
                </span>
            },
            {
                name: t("memorandum"),
                selector: "usingMemorandum",
                width: "5%",
                cell: (row) => <span>
                    {row.usingMemorandum ? <i style={{ color: "green", fontSize: '20px' }} className="fas fa-check-circle"></i> : <i style={{ color: "red", fontSize: '20px' }} className="far fa-times-circle"></i>}
                </span>
            },
            {
                name: t("created_at"),
                selector: "createdAt",
                width: "10%",
                center: true,
                cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
            },
            {
                name: t("updated_at"),
                selector: "updatedAt",
                sortable: true,
                width: "10%",
                cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
            },
            {
                name: t("image"),
                width: "5%",
                cell: row => <Button className="btn-icon btn-round" color="warning" size="sm" onClick={() => this.openImageDialog("data:image/png;base64," + row.imageBase64)}><i className="tim-icons icon-image-02"></i></Button>
            },
            {
                name: t("actions"),
                center: true,
                width: "8%",
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/company/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                    <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDeleteDialog(row)}><i className="tim-icons icon-simple-remove"></i></Button>
                </span>
            }
        ]

        const deleteModal = <DraggableModal
            isOpen={deleteCompanyModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_company")}</strong>}
            body={<div>
                {t("delete_company_message")}<strong>{companyToDelete.name}</strong>{"?"}
            </div>
            }
            footer={
                <Button color="success" onClick={this.deleteCompany}>{t("delete")}</Button>
            }
            modalStyle={{ maxWidth: '400px' }}
        />

        const imagePreviewModal = <DraggableModal
            isOpen={imageModal}
            toggle={this.closeImageDialog}
            size={"sm"}
            title={<strong>{t("image")}</strong>}
            body={<div>
                <img
                    src={imageBase64} alt="" />
            </div>
            }
            modalStyle={{ maxWidth: '400px' }}
        />

        return (
            <div className="content content-company">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col sm={12} md={4}>
                                    </Col>
                                    <Col sm={7} md={4}>

                                    </Col>
                                    <Col sm={5} md={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/company" color="success">{t("create_company")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={companyColumns}
                                        data={companies}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                            {deleteModal}
                            {imagePreviewModal}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

CompanyList.propTypes = {
    companyData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    companyData: state.companyData
})

const mapActionsToProps = { getCompanies, deleteCompany }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(CompanyList)));