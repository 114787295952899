import axios from 'axios';
import i18next from 'i18next';

let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/pdf',
    'Access-Control-Expose-Headers': 'Content-Disposition'
}

export const exportDailyDishData = (request, toastManager) => () => {
    axios.post('/v1/report/menu', request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}


/* REPORT DAILY,WEEKLY,CUSTOM */

export const getExportMacronutrients = (request, toastManager) => () => {
    axios.post('/v1/report/macronutrients', request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getExportCalories = (request, toastManager) => () => {
    axios.post(`/v1/report/calories`, request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getExportAllData = (request, toastManager) => () => {
    axios.post(`/v1/report/all`, request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

/* REPORT MEAL */

export const exportNutrientDataForMeal = (mealId, toastManager) => () => {
    axios.post(`/v1/report/meal/all`, mealId, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

/* REPORT USER */

export const getExportUserMenus = (data, toastManager) => () => {
    axios.post(`/v1/report/user/menus`, data, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getExportUserMenu = (data, toastManager) => () => {
    axios.post(`/v1/report/menu`, data, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getExportUser = (request, toastManager) => () => {
    axios.post('/v1/report/user', request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

/* REPORT FOOD CATEGORY */

export const getExportFoodCategory = (request, toastManager) => () => {
    axios.post('/v1/report/category', request, {
        headers: headers,
        responseType: 'blob'
    })
        .then(response => {
            handleContentDisposition(response);
            toastManager.add(i18next.t("export_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error_in_export"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const handleContentDisposition = (response) => {
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const contentDisposition = response.headers["content-disposition"];
    let fileName = contentDisposition.split("filename=")[1];
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}