import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, Row, Col, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import axios from 'axios';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
// Redux
import { connect } from 'react-redux';
import { getClients, getClientsByActive, searchClient, getStudents, getAdmins, searchStudents, getStudentsByActive, searchClientByActive, searchStudentsByActive } from '../../redux/actions/userActions';
// Util
import { hasRole } from '../../util/hasRole';
import { checkAuth } from '../../util/checkAuth';

const inactiveStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff #ffffff #e3e3e3'
};

const activeStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#e3e3e3 #e3e3e3 #ba54f5'
};

class UserList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            activeModal: false,
            updateActiveFlag: false,
            statusType: this.props.t("all_status"),
            filterDropdown: false,
            filterDropdownStudents: false,
            clientSearch: "",
            clientLoading: false,
            isLoading: true,
            activeTab: '1',
            studentSearch: "",
            statusTypeStudents: this.props.t("all_status"),
            activeModalStudent: false,
            activeModalAdmin: false
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        this.props.getClients(0, 10);
        hasRole('ROLE_SUPER_ADMIN') && this.props.getStudents(0, 10);
        hasRole('ROLE_SUPER_ADMIN') && this.props.getAdmins(0, 10);
        this.setState({ isLoading: false });
    }

    closeDialog = () => {
        this.setState({ activeModal: !this.state.activeModal });
    }

    closeDialogStudent = () => {
        this.setState({ activeModalStudent: !this.state.activeModalStudent });
    }

    closeDialogAdmin = () => {
        this.setState({ activeModalAdmin: !this.state.activeModalAdmin });
    }

    openDialog = (row) => {
        this.setState({ activeModal: !this.state.activeModal, updateActiveFlag: row.active, user: row });
    }

    openDialogStudent = (row) => {
        this.setState({ activeModalStudent: !this.state.activeModalStudent, updateActiveFlag: row.active, user: row });
    }

    openDialogAdmin = (row) => {
        this.setState({ activeModalAdmin: !this.state.activeModalAdmin, updateActiveFlag: row.active, user: row });
    }

    toggleFilterDropdown = () => {
        this.setState({ filterDropdown: !this.state.filterDropdown });
    }

    toggleStudentsFilterDropdown = () => {
        this.setState({ filterDropdownStudents: !this.state.filterDropdownStudents });
    }

    handleFilterTypeChangeClient = (status) => {
        const { t } = this.props;
        const { clientSearch } = this.state
        if (status === t("active_status")) {
            this.setState({ statusType: status });
            if (clientSearch !== "") {
                this.props.searchClientByActive(0, this.props.userData.size, clientSearch, true)
            }
            else {
                this.props.getClientsByActive(0, this.props.userData.size, true);
            }
        }
        else if (status === t("inactive_status")) {
            this.setState({ statusType: status });
            if (clientSearch !== "") {
                this.props.searchClientByActive(0, this.props.userData.size, clientSearch, false)
            }
            else {
                this.props.getClientsByActive(0, this.props.userData.size, false);
            }
        }
        else {
            this.setState({ statusType: status });
            if (clientSearch !== "") {
                this.props.searchClient(0, this.props.userData.size, clientSearch)
            }
            else {
                this.props.getClients(0, this.props.userData.size);
            }
        }
    }

    handleFilterTypeChangeStudents = (status) => {
        const { t } = this.props;

        if (status === t("active_status")) {
            this.setState({ statusTypeStudents: status });
            if (this.state.studentSearch !== "") {
                this.props.searchStudentsByActive(0, this.props.userData.sizeStudents, this.state.studentSearch, true)
            }
            else {
                this.props.getStudentsByActive(0, this.props.userData.sizeStudents, true);
            }
        }
        else if (status === t("inactive_status")) {
            this.setState({ statusTypeStudents: status });
            if (this.state.studentSearch !== "") {
                this.props.searchStudentsByActive(0, this.props.userData.sizeStudents, this.state.studentSearch, false)
            }
            else {
                this.props.getStudentsByActive(0, this.props.userData.sizeStudents, false);
            }
        }
        else {
            this.setState({ statusTypeStudents: status });
            if (this.state.studentSearch !== "") {
                this.props.searchStudents(0, this.props.userData.sizeStudents, this.state.studentSearch)
            }
            else {
                this.props.getStudents(0, this.props.userData.sizeStudents);
            }
        }
    }

    onChangePage = (selectedPage) => {
        const { t } = this.props;
        const { clientSearch, statusType } = this.state
        if (clientSearch !== "") {
            if (statusType === t("active_status")) {
                this.props.searchClientByActive(selectedPage - 1, this.props.userData.size, clientSearch, true)
            } else if (statusType === t("inactive_status")) {
                this.props.searchClientByActive(selectedPage - 1, this.props.userData.size, clientSearch, false)
            } else {
                this.props.searchClient(selectedPage - 1, this.props.userData.size, clientSearch);
            }
        } else if (clientSearch === "") {
            if (statusType === t("active_status")) {
                this.props.getClientsByActive(selectedPage - 1, this.props.userData.size, true)
            } else if (statusType === t("inactive_status")) {
                this.props.getClientsByActive(selectedPage - 1, this.props.userData.size, false)
            } else {
                this.props.getClients(selectedPage - 1, this.props.userData.size);
            }
        }
        else {
            this.props.getClients(selectedPage - 1, this.props.userData.size)
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        const { t } = this.props;
        const { clientSearch, statusType } = this.state
        if (clientSearch !== "" && statusType === t("all_status")) {
            this.props.searchClient(currentPage - 1, currentRowsPerPage, clientSearch)
        }
        else if (statusType === t("active_status")) {
            if (clientSearch !== "") {
                this.props.searchClientByActive(currentPage - 1, currentRowsPerPage, clientSearch, true)
            }
            else {
                this.props.getClientsByActive(currentPage - 1, currentRowsPerPage, true);
            }
        }
        else if (statusType === t("inactive_status")) {
            if (clientSearch !== "") {
                this.props.searchClientByActive(currentPage - 1, currentRowsPerPage, clientSearch, false)
            }
            else {
                this.props.getClientsByActive(currentPage - 1, currentRowsPerPage, false);
            }
        }
        else {
            this.props.getClients(currentPage - 1, currentRowsPerPage)
        }
    }

    onChangePageStudents = (selectedPage) => {
        const { t } = this.props;
        const { studentSearch, statusTypeStudents } = this.state
        if (studentSearch !== "") {
            if (statusTypeStudents === t("active_status")) {
                this.props.searchStudentsByActive(selectedPage - 1, this.props.userData.sizeStudents, studentSearch, true)
            } else if (statusTypeStudents === t("inactive_status")) {
                this.props.searchStudentsByActive(selectedPage - 1, this.props.userData.sizeStudents, studentSearch, false)
            } else {
                this.props.searchStudents(selectedPage - 1, this.props.userData.sizeStudents, studentSearch);
            }
        } else if (studentSearch === "") {
            if (statusTypeStudents === t("active_status")) {
                this.props.getStudentsByActive(selectedPage - 1, this.props.userData.sizeStudents, true)
            } else if (statusTypeStudents === t("inactive_status")) {
                this.props.getStudentsByActive(selectedPage - 1, this.props.userData.sizeStudents, false)
            } else {
                this.props.getStudents(selectedPage - 1, this.props.userData.sizeStudents);
            }
        }
        else {
            this.props.getStudents(selectedPage - 1, this.props.userData.sizeStudents)
        }
    }

    onChangeRowsPerPageStudents = (currentRowsPerPage, currentPage) => {
        const { t } = this.props;
        const { studentSearch, statusTypeStudents } = this.state
        if (studentSearch !== "" && statusTypeStudents === t("all_status")) {
            this.props.searchStudents(currentPage - 1, currentRowsPerPage, studentSearch)
        }
        else if (statusTypeStudents === t("active_status")) {
            if (studentSearch !== "") {
                this.props.searchStudentsByActive(currentPage - 1, currentRowsPerPage, studentSearch, true)
            }
            else {
                this.props.getStudentsByActive(currentPage - 1, currentRowsPerPage, true);
            }
        }
        else if (statusTypeStudents === t("inactive_status")) {
            if (studentSearch !== "") {
                this.props.searchStudentsByActive(currentPage - 1, currentRowsPerPage, studentSearch, false)
            }
            else {
                this.props.getStudentsByActive(currentPage - 1, currentRowsPerPage, false);
            }
        }
        else {
            this.props.getStudents(currentPage - 1, currentRowsPerPage)
        }
    }

    onChangePageAdmins(page, size) {
        this.props.getAdmins(page - 1, this.props.userData.sizeAdmins);
    }

    onChangeRowsPerPageAdmins = (currentRowsPerPage, currentPage) => {
        this.props.getAdmins(currentPage - 1, currentRowsPerPage)
    }

    handleClientSearch = (event) => {
        this.setState({ clientSearch: event.target.value })
    }

    getClientsSearch = () => {
        const { t } = this.props;
        const { statusType, clientSearch } = this.state
        if (statusType === t("active_status")) {
            if (clientSearch !== "") {
                this.props.searchClientByActive(0, this.props.userData.size, clientSearch, true)
            } else {
                this.props.getClientsByActive(0, this.props.userData.size, true)
            }
        }
        else if (statusType === t("inactive_status")) {
            if (clientSearch !== "") {
                this.props.searchClientByActive(0, this.props.userData.size, clientSearch, false)
            } else {
                this.props.getClientsByActive(0, this.props.userData.size, false)
            }
        }
        else {
            this.props.searchClient(0, this.props.userData.size, clientSearch);
        }
    }

    handleStudentSearch = (event) => {
        this.setState({ studentSearch: event.target.value });
    }

    getStudentsSearch = () => {
        const { t } = this.props;
        const { statusTypeStudents, studentSearch } = this.state
        if (statusTypeStudents === t("active_status")) {
            if (studentSearch !== "") {
                this.props.searchStudentsByActive(0, this.props.userData.sizeStudents, studentSearch, true)
            } else {
                this.props.getStudentsByActive(0, this.props.userData.sizeStudents, true)
            }
        }
        else if (statusTypeStudents === t("inactive_status")) {
            if (studentSearch !== "") {
                this.props.searchStudentsByActive(0, this.props.userData.sizeStudents, studentSearch, false)
            } else {
                this.props.getStudentsByActive(0, this.props.userData.sizeStudents, false)
            }
        }
        else {
            this.props.searchStudents(0, this.props.userData.sizeStudents, studentSearch);
        }
    }

    toggleActiveTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    async updateActiveStatus() {
        await axios({
            method: 'PUT',
            url: `/v1/user/${this.state.user.id}?active=${!this.state.user.active}`,
            headers: {
                'Accept': 'application/json',
            },
            data: !this.state.user.active,
        })
            .then(response => {
                this.setState({ activeModal: false, activeModalStudent: false, activeModalAdmin: false },
                    () => this.toastManager.add(this.props.t("user_updated_success"), {
                        appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                    }));
                this.props.getClients(0, this.props.userData.size);
                hasRole("ROLE_SUPER_ADMIN") && this.props.getStudents(0, this.props.userData.sizeStudents);
                hasRole("ROLE_SUPER_ADMIN") && this.props.getAdmins(0, this.props.userData.sizeAdmins);
            })
            .catch(error => {
                this.toastManager.add(this.props.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            });
    }

    render() {

        const { users, students, admins } = this.props.userData;
        const { user, activeModal, updateActiveFlag, clientSearch, studentSearch, isLoading, activeModalStudent, activeModalAdmin } = this.state;
        const { t } = this.props

        const userTableData = [
            {
                name: t("first_name"),
                selector: "firstName",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "14%" : "16%",
            },
            {
                name: t("last_name"),
                selector: "lastName",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "14%" : "16%",
            },
            {
                name: t("username"),
                selector: "username",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "15%" : "17%",
            },
            {
                name: t("email"),
                selector: "email",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "15%" : "17%",
            },
            {
                name: t("active"),
                width: hasRole('ROLE_SUPER_ADMIN') ? "8%" : "10%",
                center: "true",
                cell: (row) => <span>
                    {row.active ? <i style={{ color: "green", fontSize: '20px' }} className="fas fa-check-circle"></i> : <i style={{ color: "red", fontSize: '20px' }} className="far fa-times-circle"></i>}
                </span>
            },
            {
                name: t("joined_on"),
                width: hasRole('ROLE_SUPER_ADMIN') ? "12%" : "14%",
                sortable: true,
                cell: (row, index) =>
                    <span key={index}>
                        {moment.unix(row.joinedOn).format('DD.MM.YYYY.')}
                    </span>
            },
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "12%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const actions = {
            name: t("actions"),
            width: "10%",
            center: true,
            cell: row => <span>
                <Button className="btn-icon btn-round" color="info" size="sm" tag={Link} to={"/admin/user/" + row.username}><i className="fas fa-cog" /></Button>
                <Button className="btn-icon btn-round" color={row.active ? "warning" : "success"} size="sm" onClick={() => this.openDialog(row)}>{row.active ? <i className="fas fa-ban" /> : <i className="fas fa-check" />}</Button>
            </span>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            userTableData.push(createdBy)
            userTableData.push(actions)
        } else {
            userTableData.push(actions)
        }

        const adminsTableData = [
            {
                name: t("first_name"),
                selector: "firstName",
                sortable: true,
                width: "13%"
            },
            {
                name: t("last_name"),
                selector: "lastName",
                sortable: true,
                width: "12%"
            },
            {
                name: t("username"),
                selector: "username",
                sortable: true,
                width: "15%"
            },
            {
                name: t("email"),
                selector: "email",
                sortable: true,
                width: "20%"
            },
            {
                name: t("active"),
                width: "10%",
                center: "true",
                cell: (row) => <span>
                    {row.active ? <i style={{ color: "green", fontSize: '20px' }} className="fas fa-check-circle"></i> : <i style={{ color: "red", fontSize: '20px' }} className="far fa-times-circle"></i>}
                </span>
            },
            {
                name: t("joined_on"),
                cell: (row, index) =>
                    <span key={index}>
                        {moment.unix(row.joinedOn).format('DD.MM.YYYY.')}
                    </span>,
                sortable: true,
                width: "10%"
            },
            {
                name: t("created_by"),
                selector: "createdBy",
                sortable: true,
                width: "10%",
                cell: row => <span>
                    {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
                </span>
            },
            {
                name: t("actions"),
                width: "10%",
                center: true,
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="info" size="sm" tag={Link} to={"/admin/user/" + row.username}><i className="fas fa-cog" /></Button>
                    <Button className="btn-icon btn-round" color={row.active ? "warning" : "success"} size="sm" onClick={() => this.openDialogAdmin(row)}>{row.active ? <i className="fas fa-ban" /> : <i className="fas fa-check" />}</Button>
                </span>
            }
        ]

        let username = user !== null ? user.username : ''
        let message = updateActiveFlag ? <div>{t("deactive_client")}{' '}<strong>{username}</strong>{' ?'}</div> : <div>{t("active_client")}{' '}<strong>{username}</strong>{' ?'}</div>
        const updateActiveModal = <DraggableModal
            isOpen={activeModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("update_active_status")}</strong>}
            body={message}
            footer={
                <Button color={updateActiveFlag ? "warning" : "success"} onClick={() => this.updateActiveStatus()}>{updateActiveFlag ? t("deactivate") : t("activate")}</Button>
            }
        />

        let usernameStudent = user !== null ? user.username : ''
        let messageStudent = updateActiveFlag ? <div>{t("deactive_student")}{' '}<strong>{usernameStudent}</strong>{' ?'}</div> : <div>{t("active_student")}{' '}<strong>{usernameStudent}</strong>{' ?'}</div>
        const updateActiveModalStudent = <DraggableModal
            isOpen={activeModalStudent}
            toggle={this.closeDialogStudent}
            size={"sm"}
            title={<strong>{t("update_active_status")}</strong>}
            body={messageStudent}
            footer={
                <Button color={updateActiveFlag ? "warning" : "success"} onClick={() => this.updateActiveStatus()}>{updateActiveFlag ? t("deactivate") : t("activate")}</Button>
            }
        />

        let usernameAdmin = user !== null ? user.username : ''
        let messageAdmins = updateActiveFlag ? <div>{t("deactive_admin")}{' '}<strong>{usernameAdmin}</strong>{' ?'}</div> : <div>{t("active_admin")}{' '}<strong>{usernameAdmin}</strong>{' ?'}</div>
        const updateActiveModalAdmin = <DraggableModal
            isOpen={activeModalAdmin}
            toggle={this.closeDialogAdmin}
            size={"sm"}
            title={<strong>{t("update_active_status")}</strong>}
            body={messageAdmins}
            footer={
                <Button color={updateActiveFlag ? "warning" : "success"} onClick={() => this.updateActiveStatus()}>{updateActiveFlag ? t("deactivate") : t("activate")}</Button>
            }
        />

        return (
            <div className="content content-user">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <Nav tabs className={'nav-tabs-custom'}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '1'}
                                            style={this.state.activeTab === '1' ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('1')}>
                                            {t("clients")}
                                        </NavLink>
                                    </NavItem>
                                    {hasRole('ROLE_SUPER_ADMIN') && <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '2'}
                                            style={this.state.activeTab === '2' ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('2')}>
                                            {t("students")}
                                        </NavLink>
                                    </NavItem>}
                                    {hasRole('ROLE_SUPER_ADMIN') && <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '3'}
                                            style={this.state.activeTab === '3' ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('3')}>
                                            {t("admins")}
                                        </NavLink>
                                    </NavItem>}
                                </Nav>
                                <br />
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col>
                                                <CardHeader className={'control-header'}>
                                                    <Row>
                                                        <Col xs={4} sm={4}>
                                                            <UncontrolledDropdown isOpen={this.state.filterDropdown} toggle={this.toggleFilterDropdown}>
                                                                <DropdownToggle caret data-toggle="dropdown" color="success">
                                                                    {this.state.statusType}
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem id={"ACTIVE"} name="ACTIVE" onClick={() => this.handleFilterTypeChangeClient(t("active_status"))} value={t("active_status")}>{t("active_status")}</DropdownItem>
                                                                    <DropdownItem id={"INACTIVE"} name="INACTIVE" onClick={() => this.handleFilterTypeChangeClient(t("inactive_status"))} value={t("inactive_status")}>{t("inactive_status")}</DropdownItem>
                                                                    <DropdownItem id={"ALL"} name="ALL" onClick={() => this.handleFilterTypeChangeClient(t("all_status"))} value={t("all_status")}>{t("all_status")}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown >
                                                        </Col>
                                                        <Col xs={7} sm={3}>
                                                            <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_clients")} autoComplete="off" name="clientSearch" id="clientSearch" value={clientSearch || ''} onChange={this.handleClientSearch} onKeyPress={(event) => {
                                                                if (event.key === "Enter") {
                                                                    this.getClientsSearch()
                                                                }
                                                            }} />
                                                        </Col>
                                                        <Col xs={1} sm={1}>
                                                            <Button color="success" size="sm" type="button" onClick={() => this.getClientsSearch()}><i className="fas fa-search"></i></Button>
                                                        </Col>
                                                        <Col sm={4} style={{ textAlign: 'right' }}>
                                                            <Button tag={Link} to={{ pathname: '/admin/user', state: { roleName: "ROLE_CLIENT" } }} color="success">{t("create_user")}</Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>

                                                <CardBody>
                                                    {isLoading ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <ClipLoader
                                                                size={60}
                                                                color={"#e14eca"}
                                                                loading={isLoading}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <DataTable
                                                            columns={userTableData}
                                                            data={users}
                                                            noHeader={true}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                            paginationTotalRows={this.props.userData.totalElements}
                                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                                            noDataComponent={t("no_records")}
                                                            onChangePage={(page) => this.onChangePage(page)}
                                                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                                        />
                                                    )}
                                                    {updateActiveModal}
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    {hasRole('ROLE_SUPER_ADMIN') && <TabPane tabId="2">
                                        <Row>
                                            <Col>
                                                <CardHeader className={'control-header'}>
                                                    <Row>
                                                        <Col xs={4} sm={4}>
                                                            <UncontrolledDropdown isOpen={this.state.filterDropdownStudents} toggle={this.toggleStudentsFilterDropdown}>
                                                                <DropdownToggle caret data-toggle="dropdown" color="success">
                                                                    {this.state.statusTypeStudents}
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem id={"ACTIVE"} name="ACTIVE" onClick={() => this.handleFilterTypeChangeStudents(t("active_status"))} value={t("active_status")}>{t("active_status")}</DropdownItem>
                                                                    <DropdownItem id={"INACTIVE"} name="INACTIVE" onClick={() => this.handleFilterTypeChangeStudents(t("inactive_status"))} value={t("inactive_status")}>{t("inactive_status")}</DropdownItem>
                                                                    <DropdownItem id={"ALL"} name="ALL" onClick={() => this.handleFilterTypeChangeStudents(t("all_status"))} value={t("all_status")}>{t("all_status")}</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown >
                                                        </Col>
                                                        <Col xs={7} sm={3}>
                                                            <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_students")} autoComplete="off" name="studentSearch" id="studentSearch" value={studentSearch || ''} onChange={this.handleStudentSearch}
                                                                onKeyPress={(event) => {
                                                                    if (event.key === "Enter") {
                                                                        this.getStudentsSearch()
                                                                    }
                                                                }} />
                                                        </Col>
                                                        <Col xs={1} sm={1}>
                                                            <Button color="success" size="sm" type="button" onClick={() => this.getStudentsSearch()}><i className="fas fa-search"></i></Button>
                                                        </Col>
                                                        <Col sm={4} style={{ textAlign: 'right' }}>
                                                            <Button tag={Link} to={{ pathname: '/admin/user', state: { roleName: "ROLE_STUDENT" } }} color="success">{t("create_student")}</Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>

                                                <CardBody>
                                                    {isLoading ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <ClipLoader
                                                                size={60}
                                                                color={"#e14eca"}
                                                                loading={isLoading}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <DataTable
                                                            columns={userTableData}
                                                            data={students}
                                                            noHeader={true}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                            paginationTotalRows={this.props.userData.totalElementsStudents}
                                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                                            noDataComponent={t("no_records")}
                                                            onChangePage={(page) => this.onChangePageStudents(page)}
                                                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPageStudents(currentRowsPerPage, currentPage)}
                                                        />
                                                    )}
                                                    {updateActiveModalStudent}
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </TabPane>}
                                    {hasRole('ROLE_SUPER_ADMIN') && <TabPane tabId="3">
                                        <Row>
                                            <Col>
                                                <CardHeader className={'control-header'}>
                                                    <Row>
                                                        <Col xs={4} sm={4}>
                                                        </Col>
                                                        <Col xs={8} sm={4}>
                                                        </Col>
                                                        <Col sm={4} style={{ textAlign: 'right' }}>
                                                            <Button tag={Link} to={{ pathname: '/admin/user', state: { roleName: "ROLE_ADMIN" } }} color="success">{t("create_admin")}</Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>

                                                <CardBody>
                                                    {isLoading ? (
                                                        <div style={{ textAlign: 'center' }}>
                                                            <ClipLoader
                                                                size={60}
                                                                color={"#e14eca"}
                                                                loading={isLoading}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <DataTable
                                                            columns={adminsTableData}
                                                            data={admins}
                                                            noHeader={true}
                                                            highlightOnHover
                                                            pagination
                                                            paginationServer
                                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                                            paginationTotalRows={this.props.userData.totalElementsAdmins}
                                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                                            noDataComponent={t("no_records")}
                                                            onChangePage={(page) => this.onChangePageAdmins(page)}
                                                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPageAdmins(currentRowsPerPage, currentPage)}
                                                        />
                                                    )}
                                                    {updateActiveModalAdmin}
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </TabPane>}
                                </TabContent>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

UserList.propTypes = {
    userData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    userData: state.userData
})

const mapActionsToProps = { getClients, getClientsByActive, searchClient, getStudents, getAdmins, searchStudents, getStudentsByActive, searchClientByActive, searchStudentsByActive }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(UserList)));