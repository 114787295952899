import React from "react"
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, Row, Col, Button, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Input, Label, FormGroup } from "reactstrap"
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import ClipLoader from "react-spinners/ClipLoader"
import { Typeahead } from 'react-bootstrap-typeahead'
import { withToastManager } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import DraggableModal from '../../components/modal/DraggableModal'
import moment from 'moment'
import DataTableExtensions from "react-data-table-component-extensions"
// Redux
import { connect } from 'react-redux'
import { getDishes, getDishesByType, deleteDish, getDishTypes, searchDish, searchDishesByType, copySelectedDishes, copyAllDishes, importData } from '../../redux/actions/dishActions'
import { getCompaniesForUser } from '../../redux/actions/companyActions'
import store from '../../redux/store'
import { SET_DISH_LIST_PAGE, SET_COPY_DISH_LOADING, SET_IMPORT_LOADING } from '../../redux/types'
// Util
import { checkAuth } from '../../util/checkAuth'
import { hasRole } from "util/hasRole"

class DishList extends React.Component {

    emptyDish = {
        id: null,
        name: '',
        type: '',
        preparationType: '',
        persons: '',
        foods: []
    }

    constructor(props) {
        super(props);
        this.state = {
            deleteDishModal: false,
            dishToDelete: this.emptyDish,
            statusType: this.props.t('all_status'),
            filterDropdown: false,
            dishSearch: "",
            sheet: 'NormativReport',
            isLoading: true,
            typeEnum: "ALL",
            company: null,
            dishIds: []
        };

        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        this.props.getDishes(this.props.dishData.page, this.props.dishData.size);
        this.props.getDishTypes();
        hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
        this.setState({ isLoading: false });
    }

    handleChange = (id) => {
        let { dishIds } = this.state
        if (!dishIds.includes(id)) { dishIds.push(id) }
        else { dishIds.splice(dishIds.indexOf(id), 1); }
        this.setState({ dishIds: dishIds })
    }

    onChangePage = (selectedPage) => {
        const { dishSearch, typeEnum, statusType } = this.state
        store.dispatch({
            type: SET_DISH_LIST_PAGE,
            payload: {
                page: selectedPage - 1
            }
        })
        if (dishSearch !== "") {
            if (typeEnum === "ALL" && statusType === this.props.t('all_status')) {
                this.props.searchDish(selectedPage - 1, this.props.dishData.size, dishSearch)
            } else if (dishSearch !== "" && typeEnum !== "ALL" && statusType !== this.props.t('all_status')) {
                this.props.searchDishesByType(selectedPage - 1, this.props.dishData.size, dishSearch, typeEnum)
            }
        }
        else if (typeEnum !== "ALL" && statusType !== this.props.t('all_status')) {
            if (dishSearch !== "") {
                this.props.searchDishesByType(selectedPage - 1, this.props.dishData.size, dishSearch, typeEnum)
            }
            else {
                this.props.getDishesByType(selectedPage - 1, this.props.dishData.size, typeEnum);
            }
        }
        else {
            this.props.getDishes(selectedPage - 1, this.props.dishData.size)
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        const { dishSearch, typeEnum, statusType } = this.state
        store.dispatch({
            type: SET_DISH_LIST_PAGE,
            payload: {
                size: currentRowsPerPage,
                page: currentPage - 1
            }
        })
        if (dishSearch !== "" && typeEnum === "ALL" && statusType === this.props.t('all_status')) {
            this.props.searchDish(currentPage - 1, currentRowsPerPage, dishSearch)
        }
        else if (typeEnum !== "ALL" && statusType !== this.props.t('all_status')) {
            if (dishSearch !== "") {
                this.props.searchDishesByType(currentPage - 1, currentRowsPerPage, dishSearch, typeEnum)
            }
            else {
                this.props.getDishesByType(currentPage - 1, currentRowsPerPage, typeEnum);
            }
        }
        else {
            this.props.getDishes(currentPage - 1, currentRowsPerPage)
        }
    }

    openDialogDelete = (row) => {
        this.setState({ deleteDishModal: !this.state.deleteDishModal, dishToDelete: row, });
    }

    closeDialog = () => {
        this.setState({ deleteDishModal: !this.state.deleteDishModal });
    }

    openImportDialog = () => {
        store.dispatch({
            type: SET_IMPORT_LOADING,
            payload: {
                importModal: true
            }
        })
    }

    closeImportDialog = () => {
        store.dispatch({
            type: SET_IMPORT_LOADING,
            payload: {
                importModal: false,
                selectedFiles: []
            }
        })
    }

    toggleFilterDropdown = () => {
        this.setState({ filterDropdown: !this.state.filterDropdown });
    }

    handleFilterTypeChange = (type, typeEnum) => {
        const { dishSearch } = this.state
        this.setState({ statusType: type, typeEnum: typeEnum });
        if (typeEnum === "ALL") {
            if (dishSearch !== "") {
                this.props.searchDish(0, this.props.dishData.size, dishSearch);
            }
            else {
                this.props.getDishes(0, this.props.dishData.size);
            }
        } else {
            if (dishSearch !== "") {
                this.props.searchDishesByType(0, this.props.dishData.size, dishSearch, typeEnum)
            } else if (typeEnum !== "ALL") {
                this.props.getDishesByType(0, this.props.dishData.size, typeEnum);
            } else {
                this.props.getDishes(0, this.props.dishData.size);
            }
        }
    }

    deleteDish = () => {
        this.props.deleteDish(this.toastManager, this.state.dishToDelete.id);
        this.setState({ deleteDishModal: false });
    }

    handleDishSearch = (event) => {
        this.setState({ dishSearch: event.target.value });
    }

    getDishSearch = () => {
        const { typeEnum, dishSearch, statusType } = this.state
        if (typeEnum !== "ALL" && statusType !== this.props.t('all_status')) {
            if (dishSearch !== "") {
                this.props.searchDishesByType(0, this.props.dishData.size, dishSearch, typeEnum)
            } else {
                this.props.searchDishesByType(0, this.props.dishData.size, dishSearch, typeEnum)
            }
        }
        else {
            this.props.searchDish(0, this.props.dishData.size, dishSearch);
        }
    }

    handleFileUpload = (event) => {
        store.dispatch({
            type: SET_IMPORT_LOADING,
            payload: {
                importModal: true,
                selectedFiles: event.target.files
            }
        })
    }

    handleChangeSheet = (e) => {
        const target = e.target;
        const value = target.value;
        let sheet = { ...this.state.sheet };
        sheet = value;
        this.setState({ sheet: sheet });
    }

    handleFileSubmit = () => {
        const data = new FormData()
        Array.from(this.props.dishData.selectedFiles).forEach(file => data.append('files', file))
        data.append('sheet', this.state.sheet)

        store.dispatch({
            type: SET_IMPORT_LOADING,
            payload: {
                isLoadingImport: true
            }
        })
        this.props.importData(this.toastManager, data)
    }

    copyDialog = (copyAllDialog) => {
        if (copyAllDialog === false) {
            store.dispatch({ type: SET_COPY_DISH_LOADING, payload: { copyAllDialog: true, copyDialog: true } })
        }
        else if (copyAllDialog === undefined) {
            store.dispatch({
                type: SET_COPY_DISH_LOADING, payload: { copyAllDialog: false, copyDialog: true }
            })
        }
    }

    closeCopyDialog = () => {
        store.dispatch({
            type: SET_COPY_DISH_LOADING,
            payload: {
                copyDialog: false
            }
        })
        this.setState({ company: null })
    }

    handleChangeCompany = (companyEntry) => {
        let company = { ...this.state.company };
        if (companyEntry.length > 0) {
            company = companyEntry[0];
        } else { company = null; }
        this.setState({ company: company })
    }

    copyData = () => {
        const { dishIds, company } = this.state
        const requestData = {
            ids: dishIds,
            companyId: company.id
        }
        this.props.copySelectedDishes(requestData, this.toastManager)
    }

    render() {
        const { dishes, totalElements, dishTypes, copyDialog, copyLoading, copyAllDialog, isLoadingImport, selectedFiles, importModal } = this.props.dishData;
        const { companiesForUser } = this.props.companyData
        const { deleteDishModal, dishToDelete, isLoading, sheet, dishIds, company } = this.state;
        const { t } = this.props

        const disabledImportButton = selectedFiles !== undefined && selectedFiles.length > 0 && sheet !== ''

        let filterDishTypeOpions;
        filterDishTypeOpions = dishTypes.map((type, key) => {
            return <DropdownItem value={type} onClick={(e) => { this.handleFilterTypeChange(t(type), type) }} key={key}>{t(type)}</DropdownItem>
        })

        const deleteModal = <DraggableModal
            isOpen={deleteDishModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_dish")}</strong>}
            body={<div>
                {t("delete_dish_message")}{' '}<strong>{dishToDelete.name}</strong>{' ?'}
            </div>}
            footer={
                <Button color="success" onClick={this.deleteDish}>{t("delete")}</Button>
            }
        />

        const copyToOtherCompany = <DraggableModal
            isOpen={copyDialog}
            toggle={this.closeCopyDialog}
            size={"sm"}
            title={<strong>{t("copy_to_another_company")}</strong>}
            body={<div>
                <FormGroup>
                    <Typeahead
                        selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
                        id="company"
                        clearButton
                        labelKey="name"
                        onChange={this.handleChangeCompany}
                        options={companiesForUser}
                        placeholder={t("select_company")}
                        paginationText={t("display_additional")}
                        emptyLabel={t("no_matches_found")}
                    />
                </FormGroup>
            </div>}
            footer={
                <Button style={{ width: '82.64px', height: '40px' }} color="success" disabled={company === null} onClick={copyAllDialog ? () => this.props.copyAllDishes(company.id, this.toastManager) : () => this.copyData()}>{copyLoading ? <ClipLoader
                    size={20}
                    color={"#e14eca"}
                    loading={copyLoading}
                /> : t("copy")}</Button>}
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        const importFileModal = <DraggableModal
            isOpen={importModal}
            toggle={this.closeImportDialog}
            size={"sm"}
            title={<strong>{t("import_excel_file")}</strong>}
            body={<Row>
                <Col md={12}>
                    <Input type="file" id="file" multiple onChange={this.handleFileUpload} accept=".xlsx, .xls"></Input><br />
                    <Row>
                        <Col md={3}>
                            <Label>{t("sheet")}</Label>
                        </Col>
                        <Col md={5}>
                            <Input name="sheet" type="test" onChange={this.handleChangeSheet} value={this.state.sheet} autoComplete="off" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button style={{ width: '131px', height: '40px' }} className="button" disabled={!disabledImportButton || isLoadingImport} color="success" type="button" onClick={this.handleFileSubmit}>
                                {isLoadingImport ? <ClipLoader
                                    size={20}
                                    color={"#e14eca"}
                                    loading={isLoadingImport}
                                /> : t("upload")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>}
        />

        const dishesColumns = [
            {
                name: t("name"),
                selector: "name",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "25%" : "28%",
            },
            {
                name: t("types"),
                width: hasRole('ROLE_SUPER_ADMIN') ? "20%" : "24%",
                center: "true",
                cell: row =>
                    <span>
                        {row.types.map(type => { return t(type) }).join(", ")}
                    </span>
            },
            {
                name: t("persons"),
                selector: "persons",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "10%" : "12%"
            }
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "10%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const createdAt = {
            name: t("created_at"),
            selector: "createdAt",
            sortable: true,
            width: "12%",
            cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
        }

        const updatedAt = {
            name: t("updated_at"),
            selector: "updatedAt",
            sortable: true,
            width: "10%",
            cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
        }

        const actions = {
            name: t("actions"),
            width: "10%",
            center: true,
            cell: row => <span>
                <Button className="btn-icon btn-round" color="success" size="sm" onClick={() => this.props.history.push("/admin/dish/" + row.id)}><i className="tim-icons icon-pencil"></i></Button>
                <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDialogDelete(row)}><i className="tim-icons icon-simple-remove"></i></Button>
            </span>
        }

        const copy = {
            name: t("copy"),
            width: '5%',
            cell: row =>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="select" id="select" onChange={() => this.handleChange(row.id)} style={{ position: 'relative' }} checked={dishIds.some(item => item === row.id)} />
                        <span className="form-check-sign">
                            <span className="check" />
                        </span>
                    </Label>
                </FormGroup>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            dishesColumns.push(createdBy)
            dishesColumns.push(createdAt)
            dishesColumns.push(updatedAt)
            dishesColumns.push(actions)
            dishesColumns.push(copy)
        } else {
            dishesColumns.push(createdAt)
            dishesColumns.push(updatedAt)
            dishesColumns.push(actions)
        }

        const tableData = {
            columns: dishesColumns,
            data: dishes,
            filterHidden: false,
            filter: false,
            export: hasRole("ROLE_SUPER_ADMIN") ? true : false,
            exportHeaders: hasRole("ROLE_SUPER_ADMIN") ? true : false,
            print: hasRole("ROLE_SUPER_ADMIN") ? true : false
        };

        return (
            <div className="content content-dish">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col xs={4} sm={4}>
                                        <UncontrolledDropdown isOpen={this.state.filterDropdown} toggle={this.toggleFilterDropdown}>
                                            <DropdownToggle caret data-toggle="dropdown" color="success" style={{ marginLeft: '10px' }}>
                                                {this.state.statusType}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem id={t("all_status")} name={t("all_status")} onClick={() => this.handleFilterTypeChange(t("all_status"), "ALL")} value={t("all_status")}>{t("all_status")}</DropdownItem>
                                                {filterDishTypeOpions}
                                            </DropdownMenu>
                                        </UncontrolledDropdown >
                                    </Col>
                                    <Col xs={7} sm={3}>
                                        <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_dishes")} autoComplete="off" name="dishSearch" id="dishSearch" value={this.state.dishSearch || ''} onChange={this.handleDishSearch}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.getDishSearch()
                                                }
                                            }} />
                                    </Col>
                                    <Col xs={1} sm={1}>
                                        <Button color="success" size="sm" type="button" onClick={() => this.getDishSearch()}><i className="fas fa-search"></i></Button>
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/dish" color="success">{t("create_dish")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTableExtensions {...tableData}>
                                        <DataTable
                                            noHeader={true}
                                            columns={dishesColumns}
                                            data={dishes}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            paginationTotalRows={totalElements}
                                            paginationPerPage={this.props.dishData.size}
                                            paginationDefaultPage={this.props.dishData.page + 1}
                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                            noDataComponent={t("no_records")}
                                            onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                        />
                                    </DataTableExtensions>
                                )}
                            </CardBody>
                            {deleteModal}
                            {importFileModal}
                            {copyToOtherCompany}
                        </Card>
                        <div>
                            {/* {hasRole("ROLE_SUPER_ADMIN") && <Button color="warning" size="sm" type="button" onClick={() => this.openImportDialog()}><i className="tim-icons icon-cloud-upload-94"></i> {t("import_file")}</Button>} */}
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={dishIds.length === 0} onClick={() => this.copyDialog()}><i className="far fa-copy"></i> {t("copy_selected_to_another_company")}</Button>}
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={dishes.length === 0} onClick={() => this.copyDialog(copyAllDialog)}><i className="far fa-copy"></i> {t("copy_all_to_another_company")}</Button>}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

DishList.propTypes = {
    dishData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    dishData: state.dishData,
    companyData: state.companyData
})

const mapActionsToProps = { getDishes, getDishesByType, getDishTypes, deleteDish, searchDish, searchDishesByType, getCompaniesForUser, copySelectedDishes, copyAllDishes, importData }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(DishList)));