import React from "react"
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, Row, Col, Button, FormGroup, Input, Label } from "reactstrap"
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import ClipLoader from "react-spinners/ClipLoader"
import { withToastManager } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import DraggableModal from '../../components/modal/DraggableModal'
import moment from 'moment'
// Redux
import { connect } from 'react-redux'
import { getFodmaps, deleteFodmap, searchFodmaps, copySelectedFodmaps, copyAllFodmaps } from '../../redux/actions/fodmapActions'
import { getCompaniesForUser } from '../../redux/actions/companyActions'
import { getFoods } from '../../redux/actions/dishActions';
// Util
import { checkAuth } from '../../util/checkAuth'
import { hasRole } from "util/hasRole"
import store from "redux/store"
import { Typeahead } from "react-bootstrap-typeahead"
import { SET_COPY_FODMAP_LOADING } from "redux/types"

class Fodmaps extends React.Component {

    emptyFodmap = {
        name: '',
        description: '',
        foods: []
    }

    constructor(props) {
        super(props);
        this.state = {
            fodmap: null,
            isDescriptionModalOpen: false,
            fodmapToDelete: this.emptyFodmap,
            deleteFodmapModal: false,
            isLoading: true,
            fodmapSearch: '',
            company: null,
            fodmapIds: [],
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        Promise.all([await checkAuth(), this.props.getFodmaps(0, 10), this.props.getFoods(), hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()])
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    handleChange = (id) => {
        let { fodmapIds } = this.state
        if (!fodmapIds.includes(id)) {
            fodmapIds.push(id)
        } else {
            fodmapIds.splice(fodmapIds.indexOf(id), 1);
        }
        this.setState({ fodmapIds: fodmapIds })
    }

    onChangePage = (page, size) => {
        if (this.state.fodmapSearch !== "") {
            this.props.searchFodmaps(page - 1, this.props.fodmapData.size, this.state.fodmapSearch);
        } else {
            this.props.getFodmaps(page - 1, this.props.fodmapData.size);
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if (this.state.fodmapSearch !== "") {
            this.props.searchFodmaps(currentPage - 1, currentRowsPerPage, this.state.fodmapSearch);
        } else {
            this.props.getFodmaps(currentPage - 1, currentRowsPerPage);
        }
    }

    openDeleteDialog = (row) => {
        this.setState({ deleteFodmapModal: !this.state.deleteFodmapModal, fodmapToDelete: row });
    }

    openDescriptionDialog = (row) => {
        this.setState({ isDescriptionModalOpen: true, fodmap: row });
    }

    closeDialog = () => {
        this.setState({ deleteFodmapModal: false });
    }

    closeDescriptionModalBtn = () => {
        this.setState({ isDescriptionModalOpen: !this.state.isDescriptionModalOpen });
    }

    deleteFodmap = () => {
        this.props.deleteFodmap(this.toastManager, this.state.fodmapToDelete.id)
        this.setState({ deleteFodmapModal: false });
    }

    handleFodmapSearch = (event) => {
        this.setState({ fodmapSearch: event.target.value });
    }

    copyDialog = (copyAllDialog) => {
        if (copyAllDialog === false) {
            store.dispatch({ type: SET_COPY_FODMAP_LOADING, payload: { copyAllDialog: true, copyDialog: true } })
        }
        else if (copyAllDialog === undefined) {
            store.dispatch({
                type: SET_COPY_FODMAP_LOADING, payload: { copyAllDialog: false, copyDialog: true }
            })
        }
    }

    closeCopyDialog = () => {
        store.dispatch({
            type: SET_COPY_FODMAP_LOADING,
            payload: {
                copyDialog: false
            }
        })
        this.setState({ company: null })
    }

    handleChangeCompany = (companyEntry) => {
        let company = { ...this.state.company };
        if (companyEntry.length > 0) {
            company = companyEntry[0];
        } else { company = null; }
        this.setState({ company: company })
    }

    copyData = () => {
        const { fodmapIds, company } = this.state
        const requestData = {
            ids: fodmapIds,
            companyId: company.id
        }
        this.props.copySelectedFodmaps(requestData, this.toastManager)
    }

    render() {
        const { fodmaps, totalElements, copyAllDialog, copyDialog, copyLoading } = this.props.fodmapData
        const { companiesForUser } = this.props.companyData
        const { fodmap, isDescriptionModalOpen, isLoading, fodmapToDelete, deleteFodmapModal, company, fodmapIds } = this.state
        const { t } = this.props

        const deleteModal = <DraggableModal
            isOpen={deleteFodmapModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_fodmap")}</strong>}
            body={<div>
                {t("delete_fodmap_message")}<strong>{fodmapToDelete.name}</strong>{"?"}
            </div>
            }
            footer={
                <Button color="success" onClick={this.deleteFodmap}>{t("delete")}</Button>
            }
            modalStyle={{ maxWidth: '600px' }}
        />

        const descriptionModal = <DraggableModal
            isOpen={isDescriptionModalOpen}
            toggle={this.closeDescriptionModalBtn}
            size={"sm"}
            title={<strong>{t("fodmap_description")}</strong>}
            body={<Row>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            disabled
                            name="description"
                            type="textarea"
                            rows="4"
                            value={fodmap && fodmap.description}
                        />
                    </FormGroup>
                </Col>
            </Row>}
        />

        const copyToOtherCompany = <DraggableModal
            isOpen={copyDialog}
            toggle={this.closeCopyDialog}
            size={"sm"}
            title={<strong>{t("copy_to_another_company")}</strong>}
            body={<div>
                <FormGroup>
                    <Typeahead
                        selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
                        id="company"
                        clearButton
                        labelKey="name"
                        onChange={this.handleChangeCompany}
                        options={companiesForUser}
                        placeholder={t("select_company")}
                        paginationText={t("display_additional")}
                        emptyLabel={t("no_matches_found")}
                    />
                </FormGroup>
            </div>}
            footer={
                <Button style={{ width: '82.64px', height: '40px' }} color="success" disabled={company === null} onClick={copyAllDialog ? () => this.props.copyAllFodmaps(company.id, this.toastManager) : () => this.copyData()}>{copyLoading ? <ClipLoader
                    size={20}
                    color={"#e14eca"}
                    loading={copyLoading}
                /> : t("copy")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        const fodmapColumns = [
            {
                name: t("name"),
                selector: "name",
                sortable: true,
                width: hasRole("ROLE_SUPER_ADMIN") ? "18%" : "20%"
            },
            {
                name: t("description"),
                selector: "description",
                width: hasRole("ROLE_SUPER_ADMIN") ? "9%" : "10%",
                center: true,
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="warning" size="sm" onClick={() => this.openDescriptionDialog(row)}><i className="fas fa-info"></i></Button>
                </span>
            },
            {
                name: t("foods"),
                selector: "foods",
                width: hasRole("ROLE_SUPER_ADMIN") ? "33%" : "40%",
                center: true,
                cell: row => <span>
                    {row.foods.map(data => {
                        return data.name
                    }).join(", ")}
                </span>
            }
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "9%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const createdAt = {
            name: t("created_at"),
            selector: "createdAt",
            sortable: true,
            width: "9%",
            cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
        }

        const updatedAt = {
            name: t("updated_at"),
            selector: "updatedAt",
            sortable: true,
            width: "9%",
            cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
        }

        const actions = {
            name: t("actions"),
            center: true,
            width: "8%",
            cell: row => <span>
                <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/fodmap/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDeleteDialog(row)}><i className="tim-icons icon-simple-remove"></i></Button>
            </span>
        }

        const copy = {
            name: t("copy"),
            width: "5%",
            center: true,
            cell: row =>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="select" id="select" onChange={() => this.handleChange(row.id)} style={{ position: 'relative' }} checked={fodmapIds.some(item => item === row.id)} />
                        <span className="form-check-sign">
                            <span className="check" />
                        </span>
                    </Label>
                </FormGroup>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            fodmapColumns.push(createdBy)
            fodmapColumns.push(createdAt)
            fodmapColumns.push(updatedAt)
            fodmapColumns.push(actions)
            fodmapColumns.push(copy)
        } else {
            fodmapColumns.push(createdAt)
            fodmapColumns.push(updatedAt)
            fodmapColumns.push(actions)
        }

        return (
            <div className="content content-diet">
                {isLoading ? (
                    <div style={{ textAlign: 'center' }}>
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                ) : <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col sm={12} md={4}>
                                    </Col>
                                    <Col sm={7} md={3}>
                                        <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_fodmaps")} autoComplete="off" name="fodmapSearch" id="fodmapSearch" value={this.state.fodmapSearch || ''} onChange={this.handleFodmapSearch}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.state.fodmapSearch === "" ? this.props.getFodmaps(0, 10) : this.props.searchFodmaps(0, this.props.fodmapData.size, this.state.fodmapSearch)
                                                }
                                            }} />
                                    </Col>
                                    <Col xs={1} sm={1}>
                                        <Button color="success" size="sm" type="button" onClick={this.state.fodmapSearch === "" ? () => this.props.getFodmaps(0, 10) : () => this.props.searchFodmaps(0, this.props.fodmapData.size, this.state.fodmapSearch)}><i className="fas fa-search"></i></Button>
                                    </Col>
                                    <Col sm={5} md={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/fodmap" color="success">{t("create_fodmap")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={fodmapColumns}
                                    data={fodmaps}
                                    noHeader={true}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                    paginationTotalRows={totalElements}
                                    paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                    noDataComponent={t("no_records")}
                                    onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                />
                            </CardBody>
                            {deleteModal}
                            {descriptionModal}
                            {copyToOtherCompany}
                        </Card>
                        <div>
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={fodmapIds.length === 0} onClick={() => this.copyDialog()}><i className="far fa-copy"></i> {t("copy_selected_to_another_company")}</Button>}
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={fodmaps && fodmaps.length === 0} onClick={() => this.copyDialog(copyAllDialog)}><i className="far fa-copy"></i> {t("copy_all_to_another_company")}</Button>}
                        </div>
                    </Col>
                </Row>}

            </div>
        );
    }
}

Fodmaps.propTypes = {
    fodmapData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    fodmapData: state.fodmapData,
    companyData: state.companyData
})

const mapActionsToProps = { getFodmaps, deleteFodmap, searchFodmaps, getFoods, getCompaniesForUser, copySelectedFodmaps, copyAllFodmaps }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Fodmaps)));