import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import { getAuditLogs } from '../redux/actions/auditLogActions';
// Util
import { checkAuth } from '../util/checkAuth';

class AuditLogs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth();
        this.props.getAuditLogs(0, 30);
        this.setState({ isLoading: false });
    }

    onChangePage = (page, size) => {
        this.props.getAuditLogs(page - 1, this.props.auditLogData.size);
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        this.props.getAuditLogs(currentPage - 1, currentRowsPerPage);
    }

    render() {
        const { auditLogs, totalElements } = this.props.auditLogData;
        const { isLoading } = this.state;
        const { t } = this.props;

        const auditLogColumns = [
            {
                name: t("action"),
                selector: "action",
                width: "20%"
            },
            {
                name: t("description"),
                selector: "description",
                width: "40%"
            },
            {
                name: t("status"),
                selector: "status",
                width: "10%",
                cell: (row) => <span>
                    {row.status === "SUCCESS" ? <span style={{ color: "green", fontWeight: 'bold' }}>{row.status}</span> : <span style={{ color: "red", fontWeight: 'bold' }}>{row.status}</span>}
                </span>
            },
            {
                name: t("timestamp"),
                selector: "timestamp",
                width: "15%",
                cell: row => moment.unix(row.timestamp).format('DD.MM.YYYY. HH:mm')
            },
            {
                name: t("user"),
                selector: "user",
                width: "15%"
            }
        ]

        return (
            <div className="content content-company">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={auditLogColumns}
                                        data={auditLogs}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationRowsPerPageOptions={[30, 40, 50, 60]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

AuditLogs.propTypes = {
    auditLogData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auditLogData: state.auditLogData
})

const mapActionsToProps = { getAuditLogs }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(AuditLogs)));