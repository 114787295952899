import React from "react";
import { Prompt } from "react-router-dom";
import PropTypes from 'prop-types';
import { Card, Form, FormGroup, Row, Col, Button, Label, Input, CardHeader, CardTitle, CardBody } from "reactstrap";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { createCompany, updateCompany } from '../../redux/actions/companyActions';
// Util
import { checkAuth } from '../../util/checkAuth';

class Company extends React.Component {

    emptyCompany = {
        watermarkEnabled: false,
        usingMemorandum: false,
        imageBase64: null,
        name: '',
        email: '',
        responsiblePerson: '',
        responsiblePersonsContact: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            company: this.emptyCompany,
            initialCompany: this.emptyCompany,
            validEmail: false,
            file: ''
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.setState({ isLoading: false });
        await checkAuth();
        this.getCompanyById();
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let company = { ...this.state.company };
        company[name] = value;

        this.setState({ company: company });
        this.testEmail(company.email);
    }

    testEmail(email) {
        if (email === undefined || email.length === 0) return false;
        let emailregex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailregex.test(email)) this.setState({ validEmail: true });
        else this.setState({ validEmail: false });
    }

    async getCompanyById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/company/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    let companyObject = {
                        ...response.data.company,
                        imageBase64: response.data.company.imageBase64 === null ? null : "data:image/png;base64," + response.data.company.imageBase64
                    }
                    this.setState({
                        company: companyObject,
                        initialCompany: JSON.parse(JSON.stringify(companyObject)),
                        isLoading: false
                    });
                })
                .catch(error => {
                    this.toastManager.add(this.props.t("get_company_by_id"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                    });
                })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { company } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialCompany: this.state.company });
        let base64Img;
        if (company.imageBase64 === null) {
            base64Img = null
        } else {
            base64Img = company.imageBase64.replace(/^data:image\/\w+;base64,/, "");
        }
        company.imageBase64 = base64Img
        if (this.props.match.params.id) {
            this.props.updateCompany(this.toastManager, this.props.match.params.id, company, this.props.history);
        } else {
            this.props.createCompany(this.toastManager, company, this.props.history);
        }
    }

    handleImageChange = (e) => {
        if (e.target.value === "") {
            return
        }
        let { company } = this.state
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            company.imageBase64 = reader.result
            this.setState({
                file: file,
            });
        }
        reader.readAsDataURL(file)

        this.setState({ company: company });
    }

    render() {

        const { company, initialCompany, isLoading, validEmail } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        const validEmailAddress = (validEmail && company.email.length > 0) ? { valid: true } : { valid: false };
        const className = validEmailAddress.valid === true ? "has-success" : "has-danger"
        const disabledButton = company.name /* && company.responsiblePerson && validEmail && company.email && company.responsiblePersonsContact */

        return (
            <div className="content content-company">
                <Prompt when={JSON.stringify(company) !== JSON.stringify(initialCompany)} message={t("warning_message")} />
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("company")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-280'}>
                                        <Input
                                            style={{ cursor: 'pointer' }}
                                            name="image"
                                            type="file"
                                            accept="image/*"
                                            onChange={this.handleImageChange}
                                        />
                                        {company.imageBase64 ? <img
                                            style={{ width: 280, height: 150, cursor: 'pointer' }}
                                            src={company.imageBase64} alt="" /> :
                                            <Button
                                                style={{ width: 280, height: 150 }}
                                                component="span"
                                                size="large"
                                                type="button">
                                                <i className="tim-icons icon-simple-add"></i>
                                                <span>{this.props.t("add_image")}</span>
                                            </Button>
                                        }
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="checkbox" name="watermarkEnabled" id="watermarkEnabled" onChange={this.handleChange} style={{ marginLeft: "20px" }}
                                                checked={company.watermarkEnabled || false} /> {t("watermark_enabled")}
                                            <span className="form-check-sign">
                                                <span className="check" />
                                            </span>
                                        </Label>
                                    </FormGroup>

                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="checkbox" name="usingMemorandum" id="usingMemorandum" onChange={this.handleChange} style={{ marginLeft: "20px" }}
                                                checked={company.usingMemorandum || false} /> {t("memorandum")}
                                            <span className="form-check-sign">
                                                <span className="check" />
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={company.name || ''}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={className}>
                                        <Label>{t("email")}</Label>
                                        <Input {...validEmailAddress} type="text" name="email" id="email" placeholder={t("email")} autoComplete="off" onChange={this.handleChange} value={company.email || ''} />
                                    </FormGroup>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("responsible_person")}</Label>
                                        <Input
                                            name="responsiblePerson"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("responsible_person")}
                                            value={company.responsiblePerson || ''}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("responsible_persons_contact")}</Label>
                                        <Input
                                            name="responsiblePersonsContact"
                                            type="textarea"
                                            rows="4"
                                            onChange={this.handleChange}
                                            placeholder={t("responsible_persons_contact_placeholder")}
                                            value={company.responsiblePersonsContact || ''}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    {
                                        this.props.match.params.id ?
                                            <Button disabled={(JSON.stringify(company) === JSON.stringify(initialCompany) /* || !validEmail */)} type="submit" color="success"> {t("update")}</Button> :
                                            <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form >
            </div >
        );
    }
}

Company.propTypes = {
    companyData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    companyData: state.companyData
})

const mapActionsToProps = { createCompany, updateCompany }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Company)))