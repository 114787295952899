import { SET_USER, LOADING_UI, STOP_LOADING_UI, SET_LOGIN_ERROR, SET_UNAUTHENTICATED, SET_COMPANIES_ON_LOGIN, LOGIN_USER_DATA, SET_CONFIGURATION_SETTINGS } from '../types';
import axios from 'axios';
import { setCookie, removeCookie } from 'redux-cookie';
import store from '../store';
import i18next from 'i18next';

export const loginUser = (userData, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('/v1/auth/login', userData)
        .then(response => {
            if (response.data.info.code === 2000) {
                dispatch({
                    type: SET_COMPANIES_ON_LOGIN,
                    payload: response.data.companies
                })
                dispatch({ type: STOP_LOADING_UI })
                dispatch({
                    type: LOGIN_USER_DATA,
                    payload: userData
                })
                history.push('/login')
            }
            else if (response.data.info.code === 200) {
                setAuthorizationHeader(response.data.token)
                dispatch(getUserData())
                dispatch({ type: STOP_LOADING_UI })
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        })
        .catch(error => {
            dispatch({
                type: SET_LOGIN_ERROR,
                payload: error.response === undefined ? { message: i18next.t("login_error_message") } : error.response.data,
            });
            dispatch({
                type: STOP_LOADING_UI
            });
        });
}

export const loginSuperAdmin = (userDataSuperAdmin, history) => (dispatch) => {
    dispatch({ type: LOADING_UI })
    axios.post('/v1/auth/superadmin/login', userDataSuperAdmin)
        .then(response => {
            setAuthorizationHeader(response.data.token)
            dispatch(getUserData())
            dispatch({ type: STOP_LOADING_UI })
            history.push('/admin/dashboard')

        })
        .catch(error => {
            dispatch({
                type: SET_LOGIN_ERROR,
                payload: error.response === undefined ? { message: i18next.t("login_error_message") } : error.response.data,
            });
            dispatch({
                type: STOP_LOADING_UI
            });
        });
}

export const changeCompany = (companyId, history) => (dispatch) => {
    axios.post(`/v1/auth/superadmin/changeCompany?companyId=${companyId}`)
        .then(response => {
            store.dispatch(removeCookie('token', { path: '/' }))
            setAuthorizationHeader(response.data.token)
            dispatch(getUserData())
            dispatch({ type: STOP_LOADING_UI })
            history.go(0);
        })
        .catch(error => {
            dispatch({
                type: SET_LOGIN_ERROR,
                payload: error.response === undefined ? { message: i18next.t("login_error_message") } : error.response.data,
            });
            dispatch({
                type: STOP_LOADING_UI
            });
        });
}

/* export const signupUser = (newUserData, history) => (dispatch) => {
    axios.post('/v1/auth/signup', newUserData)
        .then(response => {
            history.push('/');
        })
        .catch(error => {
            console.log(error.response.data);
        });
} */

export const logoutUser = () => (dispatch) => {
    store.dispatch(removeCookie('token', { path: '/' }))
    window.location.href = "/"
    axios.post('/v1/auth/logout')
        .then(response => {
            delete axios.defaults.headers.common['Authorization'];
            dispatch({ type: SET_UNAUTHENTICATED });
            window.location.href = "/"
        })
        .catch(error => {
            console.log(error);
        })
}

export const getUserData = () => (dispatch) => {
    axios.get('/v1/auth/userinfo')
        .then(response => {
            dispatch({
                type: SET_USER,
                payload: response.data.userDetails
            })
            dispatch({
                type: STOP_LOADING_UI
            })
            dispatch(getConfigurationSettings())
        })
        .catch(error => console.log(error))
}

export const getConfigurationSettings = () => (dispatch) => {
    axios.get('/v1/auth/config')
        .then(response => {
            dispatch({
                type: SET_CONFIGURATION_SETTINGS,
                payload: response.data.usingClientSpecificMenus
            })
            dispatch({
                type: STOP_LOADING_UI
            })
        })
        .catch(error => console.log(error))
}

const setAuthorizationHeader = (jwt) => {
    const token = jwt
    store.dispatch(setCookie('token', token))
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const changeAdminPassword = (toastManager, requestBody, history) => (dispatch) => {
    axios.post('/v1/auth/changePassword', requestBody)
        .then(response => {
            store.dispatch(removeCookie('token', { path: '/' }))
            dispatch({ type: STOP_LOADING_UI })
            history.go(0);
        })
        .catch(error => {
            if (error.response.data.code === 4000) {
                toastManager.add(i18next.t("current_password_incorrect"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else if (error.response.data.code === 4001) {
                toastManager.add(i18next.t("passwords_dont_match"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}
