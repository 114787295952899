import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

import authReducer from './reducers/authReducer';
import foodReducer from './reducers/foodReducer';
import dishReducer from './reducers/dishReducer';
import uiReducer from './reducers/uiReducer';
import dietReducer from './reducers/dietReducer';
import mealReducer from './reducers/mealReducer';
import dashboardReducer from './reducers/dashboardReducer';
import diagnosisReducer from './reducers/diagnosisReducer';
import reportsReducer from './reducers/reportsReducer';
import userReducer from './reducers/userReducer';
import menuEditorReducer from './reducers/menuEditorReducer';
import companyReducer from './reducers/companyReducer';
import auditLogReducer from './reducers/auditLogReducer';
import allergenReducer from './reducers/allergenReducer';
import fodmapReducer from './reducers/fodmapReducer';

const initialState = {};

const middleware = [thunk, createCookieMiddleware(Cookies)];

const reducers = combineReducers({
    auth: authReducer,
    foodData: foodReducer,
    dishData: dishReducer,
    dietData: dietReducer,
    mealData: mealReducer,
    userData: userReducer,
    dashboardData: dashboardReducer,
    diagnosisData: diagnosisReducer,
    menuEditorData: menuEditorReducer,
    reportsData: reportsReducer,
    companyData: companyReducer,
    auditLogData: auditLogReducer,
    allergenData: allergenReducer,
    fodmapData: fodmapReducer,
    UI: uiReducer
});

const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware)/* ,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() */
    )
);

export default store;
