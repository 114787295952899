import { SET_AUDIT_LOGS } from '../types';

const initialState = {
    auditLogs: [],
    size: 30,
    totalElements: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUDIT_LOGS:
            return {
                ...state,
                auditLogs: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        default:
            return state;
    }
}
