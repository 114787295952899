import jwtDecode from 'jwt-decode'
// Redux
import store from '../redux/store';
import { logoutUser } from '../redux/actions/authActions';
import { getCookie } from 'redux-cookie';

export const checkAuth = async () => {
    const token = store.dispatch(getCookie('token'));
    if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            store.dispatch(logoutUser());
            window.location.href = '/';
        }
    }
}