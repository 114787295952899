import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Row, Col, CardBody, Table, CardTitle, Button } from "reactstrap";
import { Pie } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

export class MacronutrientsDishCard extends Component {

    handleFileSubmit = (dishId, handleContentDisposition) => {

        const canvasSave = document.getElementById('chart');
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "");
        axios.post(`/v1/report/dish/chart/macro/${dishId}`, base64Img, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                'Access-Control-Expose-Headers': 'Content-Disposition'
            },
            responseType: 'blob'
        })
            .then(response => {
                handleContentDisposition(response)
            })
            .catch(error => {
                console.log(error)
            })
    }


    render() {

        const { data, options, width, height, legend, t, macronutrients } = this.props;

        let macronutrientsTable = null;
        if (macronutrients) {
            macronutrientsTable =
                <Table hover className={'mt-4'}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("energy")}</th>
                            <th>{t("carbohydrate")}</th>
                            <th>{t("fat")}</th>
                            <th>{t("protein")}</th>
                            <th>{t("fibre")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <th scope="row"><strong>{t("total")} </strong>({t("per_person")})</th>
                            <td>{macronutrients.energy && macronutrients.energy.toFixed(0)} kcal</td>
                            <td>{macronutrients.carbohydrates && macronutrients.carbohydrates.toFixed(1)} g</td>
                            <td>{macronutrients.fat && macronutrients.fat.toFixed(1)} g</td>
                            <td>{macronutrients.protein && macronutrients.protein.toFixed(1)} g</td>
                            <td>{macronutrients.fibre && macronutrients.fibre.toFixed(1)} g</td>
                        </tr>
                    </tbody>
                </Table>
        }


        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("macronutrients")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button disabled={!this.props.dishId} type="button" color="success" size="sm" onClick={() => this.handleFileSubmit(this.props.dishId, this.props.handleContentDisposition)}><i className="fas fa-download"></i></Button>

                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12} align={'center'}>
                            <div className={'chart-container'}>
                                <Pie
                                    id="chart"
                                    data={data}
                                    options={options}
                                    legend={legend}
                                    width={width}
                                    height={height} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {macronutrientsTable}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

MacronutrientsDishCard.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    legend: PropTypes.object.isRequired,
    macronutrients: PropTypes.object.isRequired,
    dishId: PropTypes.string,
    handleContentDisposition: PropTypes.func.isRequired
}

export default withTranslation()(MacronutrientsDishCard);
