// Auth reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED'
export const SET_USER = 'SET_USER'
export const LOADING_USER = 'LOADING_USER'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const SET_CONFIGURATION_SETTINGS = 'SET_CONFIGURATION_SETTINGS'
export const SET_COMPANIES_ON_LOGIN = 'SET_COMPANIES_ON_LOGIN'
export const LOGIN_USER_DATA = 'LOGIN_USER_DATA'

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const LOADING_UI = 'LOADING_UI'
export const STOP_LOADING_UI = 'STOP_LOADING_UI'
export const LOADING_DATA = 'LOADING_DATA'

// Food reducer types
export const CREATE_FOOD = 'CREATE_FOOD'
export const SET_FOODS = 'SET_FOODS'
export const UPDATE_FOOD = 'UPDATE_FOOD'
export const DELETE_FOOD = 'DELETE_FOOD'
export const SET_FOOD_CATEGORIES = 'SET_FOOD_CATEGORIES'
export const SET_UNITS_OF_MEASUREMENT = 'SET_UNITS_OF_MEASUREMENT'
export const SET_FOOD_ENUMBERS = 'SET_FOOD_ENUMBERS'
export const SET_FOOD_LIST_PAGE = 'SET_FOOD_LIST_PAGE'
export const SET_COPY_FOOD_LOADING = 'SET_COPY_FOOD_LOADING'
export const SET_IMPORT_FOOD_LOADING = 'SET_IMPORT_FOOD_LOADING'

// Dish reducer types
export const CREATE_DISH = 'CREATE_DISH'
export const SET_DISHES = 'SET_DISHES'
export const UPDATE_DISH = 'UPDATE_DISH'
export const DELETE_DISH = 'DELETE_DISH'
export const SET_DISH_TYPES = 'SET_DISH_TYPES'
export const SET_DISH_FOODS = 'SET_DISH_FOODS'
export const SET_MACRONUTRIENTS = 'SET_MACRONUTRIENTS'
export const SET_MICRONUTRIENTS = 'SET_MICRONUTRIENTS'
export const RESET_DISH_DATA = 'RESET_DISH_DATA'
export const SET_PREPARATION_TYPES = 'SET_PREPARATION_TYPES'
export const SET_MINERALS = 'SET_MINERALS'
export const SET_DISH_LIST_PAGE = 'SET_DISH_LIST_PAGE'
export const SET_COPY_DISH_LOADING = 'SET_COPY_DISH_LOADING'
export const SET_IMPORT_LOADING = 'SET_IMPORT_LOADING'

// Diet reducer types
export const CREATE_DIET = 'CREATE_DIET'
export const SET_DIETS = 'SET_DIETS'
export const UPDATE_DIET = 'UPDATE_DIET'
export const DELETE_DIET = 'DELETE_DIET'
export const SET_NUTRIENT_TYPES = 'SET_NUTRIENT_TYPES'
export const DELETE_DIET_INFO = 'DELETE_DIET_INFO'
export const SET_DIET_WAS_DELETED = 'SET_DIET_WAS_DELETED'
export const SET_COPY_DIET_LOADING = 'SET_COPY_DIET_LOADING'
export const SET_DIET_DELETE_MODAL = 'SET_DIET_DELETE_MODAL'

// Meal reducer types
export const CREATE_MEAL = 'CREATE_MEAL'
export const SET_MEALS = 'SET_MEALS'
export const UPDATE_MEAL = 'UPDATE_MEAL'
export const DELETE_MEAL = 'DELETE_MEAL'
export const SET_MEAL_DISHES = 'SET_MEAL_DISHES'
export const SET_MACRONUTRIENTS_ON_MEAL = 'SET_MACRONUTRIENTS_ON_MEAL'
export const SET_MICRONUTRIENTS_ON_MEAL = 'SET_MICRONUTRIENTS_ON_MEAL'
export const SET_MINERAL_TABLE_ON_MEAL = 'SET_MINERAL_TABLE_ON_MEAL'
export const SET_COPY_MEAL_LOADING = 'SET_COPY_MEAL_LOADING'

// User reducer types
export const CREATE_USER = 'CREATE_USER'
export const SET_USERS = 'SET_USERS'
export const SET_USER_DIETS = 'SET_USER_DIETS'
export const SET_USER_ROLES = 'SET_USER_ROLES'
export const SET_USER_DIAGNOSES = 'SET_USER_DIAGNOSES'
export const SET_STUDENTS = 'SET_STUDENTS'
export const SET_ADMINS = 'SET_ADMINS'
export const SET_USER_MENUS_TABLE = 'SET_USER_MENUS_TABLE'
export const SET_USER_ALLERGENS = 'SET_USER_ALLERGENS'
export const SET_USER_FODMAPS = 'SET_USER_FODMAPS'

// Dashboard reducer types
export const LOADING_DASHBOARD_DATA = 'LOADING_DASHBOARD_DATA'
export const SET_TOTAL_CLIENTS = 'SET_TOTAL_CLIENTS'
export const SET_TOTAL_MEALS = 'SET_TOTAL_MEALS'
export const SET_TOTAL_DIETS = 'SET_TOTAL_DIETS'
export const SET_TOTAL_DISHES = 'SET_TOTAL_DISHES'
export const SET_CLIENTS_WITHOUT_MEAL = 'SET_CLIENTS_WITHOUT_MEAL'
export const SET_LATEST_CLIENTS = 'SET_LATEST_CLIENTS'
export const SET_LATEST_MEALS = 'SET_LATEST_MEALS'
export const SET_UNAPPROVED_FOODS = 'SET_UNAPPROVED_FOODS'
export const SET_UNAPPROVED_DISHES = 'SET_UNAPPROVED_DISHES'
export const SET_UNAPPROVED_DIETS = 'SET_UNAPPROVED_DIETS'
export const SET_UNAPPROVED_MEALS = 'SET_UNAPPROVED_MEALS'

// Diagnosis reducer types
export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS'
export const SET_DIAGNOSES = 'SET_DIAGNOSES'
export const DELETE_DIAGNOSIS = 'DELETE_DIAGNOSIS'

// Menu editor reducer types
export const SET_CALENDAR_MEALS = 'SET_CALENDAR_MEALS'
export const SET_LOADING_CALENDAR_ENTRIES = 'SET_LOADING_CALENDAR_ENTRIES'
export const SET_MENU_BY_DIET = 'SET_MENU_BY_DIET'
export const SET_MENU_DISHES = 'SET_MENU_DISHES'
export const SET_LATEST_DIET_NAME = 'SET_LATEST_DIET_NAME'
export const SET_DISH_NORMATIVES = 'SET_DISH_NORMATIVES'
export const SET_FFQ = 'SET_FFQ'
export const SET_MEAL_NORMATIVES = 'SET_MEAL_NORMATIVES'
export const SET_DIET_CLIENTS = 'SET_DIET_CLIENTS'
export const SET_DIET_CLIENTS_COPY = 'SET_DIET_CLIENTS_COPY'
export const SET_MENU_NUTRIENTS_OUT_OF_BOUNDS = 'SET_MENU_NUTRIENTS_OUT_OF_BOUNDS'
export const SET_MODAL_ISSUE = 'SET_MODAL_ISSUE'

// Reports reducer types
export const SET_REPORTS_ENTRIES = 'SET_REPORTS_ENTRIES'

// Company reducer types
export const SET_COMPANIES = 'SET_COMPANIES'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const SET_COMPANIES_FOR_USER = 'SET_COMPANIES_FOR_USER'

// Audit logs reducer types
export const SET_AUDIT_LOGS = 'SET_AUDIT_LOGS'

// Allergen reducer types
export const SET_ALLERGENS = 'SET_ALLERGENS'
export const CREATE_ALLERGEN = 'CREATE_ALLERGEN'
export const DELETE_ALLERGEN = 'DELETE_ALLERGEN'
export const SET_ALLERGENS_ALERT = 'SET_ALLERGENS_ALERT'
export const SET_ALLERGENS_ALERT_DISHES = 'SET_ALLERGENS_ALERT_DISHES'
export const RESET_ALERGEN_DATA = 'RESET_ALERGEN_DATA'
export const SET_ALLERGENS_ON_MENU = 'SET_ALLERGENS_ON_MENU'
export const SET_COPY_ALLERGEN_LOADING = 'SET_COPY_ALLERGEN_LOADING'

// Fodmap reducer types
export const SET_FODMAPS = 'SET_FODMAPS'
export const CREATE_FODMAP = 'CREATE_FODMAP'
export const DELETE_FODMAP = 'DELETE_FODMAP'
export const SET_FODMAP_ALERT = 'SET_FODMAP_ALERT'
export const SET_FODMAP_ALERT_DISHES = 'SET_FODMAP_ALERT_DISHES'
export const RESET_FODMAP_DATA = 'RESET_FODMAP_DATA'
export const SET_FODMAPS_ON_MENU = 'SET_FODMAPS_ON_MENU'
export const SET_COPY_FODMAP_LOADING = 'SET_COPY_FODMAP_LOADING'