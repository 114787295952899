import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios'
import { Card, CardHeader, CardBody, FormGroup, Row, Col, Button, Label, Badge, Input, CardTitle, Table, Alert } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Bar, Pie } from 'react-chartjs-2';
import DraggableModal from '../../components/modal/DraggableModal'
// Redux
import { connect } from 'react-redux';
import { getMeals, getDishes, createEntry, updateEntry, getMenuByDiet, reloadClients, getNutrientsOutOfBounds } from '../../redux/actions/menuEditorActions';
import { getAllergensIssue } from '../../redux/actions/allergenActions'
import { getFodmapsIssue } from '../../redux/actions/fodmapActions'
// Util
import { checkAuth } from '../../util/checkAuth';
// Style
import '../../components/calendar/CalendarTable.css';

class MenuDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuDetails: {},
            isLoading: false,
            selectedEntryMeals: [],
            selectedEntryDishes: [],
            modalEdit: false,
            entryTypeCard: '',
            selectedEntryId: null,
            selectedMenuId: null,
            inConflictWithDiet: false,
            startDay: moment().startOf('isoweek'),
            endDay: moment().endOf('isoweek'),
            modalIssue: false,
            menuClients: [],
            dishIds: [],
            modalIssueAllergen: false
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        await checkAuth()
        this.getMenuDetailsById()
        this.props.getMeals()
        this.props.getDishes()
    }

    async getMenuDetailsById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/menu/${this.props.match.params.id}/details`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {

                    let IDs = []
                    response.data.menuDetails.calendarEntries.map(data => {
                        return data.dishes.forEach(d => {
                            IDs.push(d.id)
                        })
                    })
                    this.setState({
                        menuDetails: response.data.menuDetails,
                        calendarEntries: response.data.menuDetails.calendarEntries,
                        menuClients: response.data.menuDetails.menuClients,
                        macronutrients: response.data.menuDetails.macronutrients,
                        selectedMenuId: response.data.menuDetails.id,
                        inConflictWithDiet: response.data.menuDetails.inConflictWithDiet,
                        isLoading: false,
                        dishIds: IDs
                    });
                    this.menuDetailsIssue(response.data.menuDetails.id)
                    this.getAllergensIssue()
                    this.getFodmapsIssue()
                })
                .catch(error => {
                    this.setState(
                        () => this.toastManager.add(this.props.t("get_menu_details_by_id"), {
                            appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                        }));
                })
        }
    }

    openEditCard = (data) => {
        this.setState({ modalEdit: true })
        let { selectedEntryDishes, selectedEntryMeals, dishIds } = this.state
        data.dishes.map(dish => {
            return selectedEntryDishes.push(dish)

        })
        data.dishes.map(dish => {
            return dishIds.push(dish.id)

        })
        data.meals.map(meal => {
            return selectedEntryMeals.push(meal)
        })
        this.setState({ selectedEntryId: data.id, entryTypeCard: data.entryType })
    }

    closeEditCard = () => {
        this.setState({
            modalEdit: false, entryTypeCard: '', selectedEntryId: null, selectedEntryMeals: [], selectedEntryDishes: []
        })
    }

    handleChangeMeal = (meals) => {
        this.setState({ selectedEntryMeals: meals })
    }

    handleChangeDishes = (dishes) => {
        this.setState({ selectedEntryDishes: dishes })
    }

    getMenuByDiet = () => {
        const timeInterval = {
            startDay: this.state.startDay.format('YYYY-MM-DD'),
            endDay: this.state.endDay.format('YYYY-MM-DD')
        }
        let data = {
            diet: this.state.menuDetails.diet && this.state.menuDetails.diet.id,
            timeInterval: timeInterval
        }
        this.props.getMenuByDiet(data)
    }

    handleSubmit = () => {
        let { selectedEntryMeals, selectedEntryDishes, selectedEntryId, entryTypeCard, selectedMenuId } = this.state
        if (selectedEntryId === null) {
            const createEntryRequest = {
                meals: selectedEntryMeals,
                entryType: entryTypeCard,
                menuId: selectedMenuId,
                dishes: selectedEntryDishes
            }
            Promise.all([this.props.createEntry(createEntryRequest, this.getMenuByDiet, this.toastManager)])
                .then(() => {
                    this.closeEditCard();
                    this.getMenuDetailsById();
                    this.menuDetailsIssue(selectedMenuId);
                    this.getAllergensIssue()
                    this.getFodmapsIssue()
                })
                .catch(error => {
                    console.log('Promise rejected:', error)
                })

        } else {
            const patchEntryRequest = {
                meals: selectedEntryMeals,
                dishes: selectedEntryDishes
            }
            Promise.all([this.props.updateEntry(selectedEntryId, patchEntryRequest, this.getMenuByDiet, this.toastManager)])
                .then(() => {
                    this.closeEditCard();
                    this.getMenuDetailsById();
                    this.menuDetailsIssue(selectedMenuId);
                    this.getAllergensIssue()
                    this.getFodmapsIssue()
                })
                .catch(error => {
                    console.log('Promise rejected:', error)
                })
        }
    }

    getEntryData = (calendarEntries, entryType) => {
        let meals = []
        let dishes = []
        let id = null
        let entryTypeCard = ''
        calendarEntries && calendarEntries.forEach(calendarEntry => {
            if (calendarEntry.entryType === entryType) {
                id = calendarEntry.id
                entryTypeCard = entryType
                meals = calendarEntry.meals.map(meal => {
                    return meal
                })
                dishes = calendarEntry.dishes.map(dish => {
                    return dish
                })
            } else {
                entryTypeCard = entryType
            }
        })
        return {
            id: id,
            entryType: entryTypeCard,
            meals: meals,
            dishes: dishes
        }
    }

    menuDetailsIssue = (id) => {
        this.props.getNutrientsOutOfBounds(this.toastManager, id)
    }

    reloadClients = () => {
        this.props.reloadClients(this.toastManager, this.props.match.params.id, () => {
            this.getMenuDetailsById()
        });
    }

    toggleModalIssue = () => {
        this.setState({ modalIssue: !this.state.modalIssue })
    }

    toggleModalIssueAllergen = () => {
        this.setState({ modalIssueAllergen: !this.state.modalIssueAllergen })
    }

    getAllergensIssue = () => {
        this.props.getAllergensIssue(this.state.selectedMenuId, this.toastManager)
    }

    getFodmapsIssue = () => {
        this.props.getFodmapsIssue(this.state.selectedMenuId, this.toastManager)
    }

    render() {

        const { isLoading, menuDetails, calendarEntries, menuClients, macronutrients, modalEdit, selectedEntryDishes, selectedEntryMeals, inConflictWithDiet, /* nutrientsOutOfBounds, */ modalIssue } = this.state;
        const { meals, dishes, nutrientsOutOfBounds } = this.props.menuEditorData;
        const { t } = this.props;
        const { usingClientSpecificMenus } = this.props.auth;
        const { menuAllergens } = this.props.allergenData;
        const { menuFodmaps } = this.props.fodmapData;

        let breakfastData = this.getEntryData(calendarEntries, 'BREAKFAST')
        let snack1Data = this.getEntryData(calendarEntries, 'SNACK1')
        let lunchData = this.getEntryData(calendarEntries, 'LUNCH')
        let snack2Data = this.getEntryData(calendarEntries, 'SNACK2')
        let dinnerData = this.getEntryData(calendarEntries, 'DINNER')
        let snack3Data = this.getEntryData(calendarEntries, 'SNACK3')
        let cardBreakfast = null
        let cardSnack1 = null
        let cardLunch = null
        let cardSnack2 = null
        let cardDinner = null
        let cardSnack3 = null
        if (calendarEntries) {
            cardBreakfast = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "BREAKFAST" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "BREAKFAST" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
            cardSnack1 = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK1" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK1" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
            cardLunch = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "LUNCH" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "LUNCH" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
            cardSnack2 = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK2" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK2" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
            cardDinner = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "DINNER" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "DINNER" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
            cardSnack3 = <CardBody style={{ cursor: 'pointer' }}>
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK3" ?
                        data.meals.map((meal, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item meal" color="info"
                                pill>{meal.name}</Badge></p></Fragment>
                        }) : null
                })}
                {calendarEntries.map(data => {
                    return data.entryType === "SNACK3" ?
                        data.dishes.map((dish, index) => {
                            return <Fragment key={index}><p><Badge className="meal-item dish" color="info"
                                pill>{dish.name}</Badge></p></Fragment>
                        }) : null
                })}
            </CardBody>
        }

        let editMealAndDishModal = <DraggableModal
            isOpen={modalEdit}
            toggle={this.closeEditCard}
            size={"sm"}
            title={<strong>{t("set_meal")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("meals")}</Label>
                            <Typeahead
                                options={meals}
                                selected={selectedEntryMeals.length > 0 ? selectedEntryMeals : []}
                                onChange={this.handleChangeMeal}
                                id="meal"
                                labelKey="name"
                                placeholder={t("choose_a_meal")}
                                clearButton
                                multiple
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("dishes")}</Label>
                            <Typeahead
                                options={dishes}
                                selected={selectedEntryDishes.length > 0 ? selectedEntryDishes : []}
                                onChange={this.handleChangeDishes}
                                id="dish"
                                labelKey="name"
                                placeholder={t("choose_a_dish")}
                                clearButton
                                multiple
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row></div>}
            footer={<Button color="success" onClick={() => this.handleSubmit()}>{t("set")}</Button>}
        />

        let menuClientsTable = []
        if (menuClients) {
            menuClientsTable = <React.Fragment>
                {menuClients.map((user, index) => {
                    return <tr key={index}>
                        <td>
                            <Input disabled type="text" name={"firstName" + index} id={"firstName" + index} value={user.firstName} />
                        </td>
                        <td>
                            <Input disabled type="text" name={"lastName" + index} id={"lastName" + index} value={user.lastName} />
                        </td>
                        <td>
                            <Input disabled type="text" name={"dateOfBirth" + index} id={"dateOfBirth" + index} value={moment(user.dateOfBirth).format("DD.MM.YYYY.")} />
                        </td>
                        <td>
                            <Input disabled type="text" name={"sex" + index} id={"sex" + index} value={t(user.sex)} />
                        </td>
                        <td>
                            <Input disabled type="text" name={"personsMultiplier" + index} id={"personsMultiplier" + index} value={user.personsMultiplier} />
                        </td>
                        <td>
                            <Input disabled type="text" name={"username" + index} id={"username" + index} value={user.username} />
                        </td>
                    </tr>
                })}
            </React.Fragment>
        }

        const nutrientSum = macronutrients && (macronutrients.carbohydratesKcal + macronutrients.fatKcal + macronutrients.proteinKcal);
        const macronutrientData = {
            macronutrientRatio: {
                carbohydrateRatio: macronutrients && macronutrients.carbohydrateRatio,
                fatRatio: macronutrients && macronutrients.fatRatio,
                proteinRatio: macronutrients && macronutrients.proteinRatio
            },
            macronutrientChartData: {
                labels: [
                    t('carbohydrate'),
                    t('fat'),
                    t('protein')
                ],
                datasets: [{
                    data: [
                        macronutrients && macronutrients.carbohydratesKcal,
                        macronutrients && macronutrients.fatKcal,
                        macronutrients && macronutrients.proteinKcal,
                    ],
                    backgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ],
                    hoverBackgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ]
                }]
            },
            macronutrientBarChart: {

                labels: [
                    t('ratio')
                ],
                datasets: [{
                    label: t("monosaccharide") + " / " + t("polysaccharide"),
                    backgroundColor: '#FCF955',
                    borderColor: ['#FCF955'],
                    data: [macronutrients && macronutrients.carbohydrateRatio]
                }, {
                    label: t("saturated") + " / " + t("unsaturated") + " " + t("fatty_acid"),
                    backgroundColor: '#F76A6E',
                    borderColor: ['#F76A6E'],
                    data: [macronutrients && macronutrients.fatRatio]
                }, {
                    label: t("animal") + " / " + t("plant") + " " + t("proteins"),
                    backgroundColor: '#68A2EB',
                    borderColor: ['#68A2EB'],
                    data: [macronutrients && macronutrients.proteinRatio]
                }],
                options: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            fontColor: "green",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            },
            macronutrientBarChartOptions: {
                options: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            fontColor: "green",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            },
            macronutrientChartOptions: {
                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                        },
                        label: function (tooltipItem, data) {
                            var dataset = data['datasets'][0];
                            var percent = ((dataset['data'][tooltipItem['index']] / nutrientSum) * 100).toFixed(1);

                            return `${t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']]} kcal (${percent}%)`;
                        }
                    },
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    titleFontSize: 16,
                    titleFontColor: '#fff',
                    bodyFontColor: '#eaeaea',
                    bodyFontSize: 14,
                    displayColors: false
                }
            }
        }

        let macronutrientsTable = null;
        if (macronutrients) {
            macronutrientsTable =
                <Table hover className={'mt-4'}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("energy")}</th>
                            <th>{t("carbohydrate")}</th>
                            <th>{t("fat")}</th>
                            <th>{t("protein")}</th>
                            <th>{t("fibre")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><strong>{t("total")} </strong>({t("per_person")})</th>
                            <td>{macronutrients.energy && macronutrients.energy.toFixed(0)} kcal</td>
                            <td>{macronutrients.carbohydrates && macronutrients.carbohydrates.toFixed(1)} g</td>
                            <td>{macronutrients.fat && macronutrients.fat.toFixed(1)} g</td>
                            <td>{macronutrients.protein && macronutrients.protein.toFixed(1)} g</td>
                            <td>{macronutrients.fibre && macronutrients.fibre.toFixed(1)} g</td>
                        </tr>
                    </tbody>
                </Table>
        }


        let nutrientsOutOfBoundsTable = null
        if (nutrientsOutOfBounds.length > 0) {
            nutrientsOutOfBoundsTable = <React.Fragment>
                <Table>
                    <thead>
                        <tr>
                            <th><strong>{t("nutrient_type")}</strong></th>
                            <th><strong>{t("value_now")}</strong></th>
                            <th><strong>{t("diet_from")}</strong></th>
                            <th><strong>{t("diet_to")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {nutrientsOutOfBounds.map((data, index) => {
                            return <tr key={index}>
                                <td>
                                    {t(data.nutrientTypeWithUnit.nutrientType) + " (" + data.nutrientTypeWithUnit.unit + ")"}
                                </td>
                                <td>
                                    {data.value}
                                </td>
                                <td>
                                    {data.dietFrom}
                                </td>
                                <td>
                                    {data.dietTo}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        }

        let issueModal = <DraggableModal
            isOpen={modalIssue}
            toggle={this.toggleModalIssue}
            size={"sm"}
            title={<strong>{t("issue_diet")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {nutrientsOutOfBoundsTable}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        let issueAllergenModal = <DraggableModal
            isOpen={this.state.modalIssueAllergen}
            toggle={this.toggleModalIssueAllergen}
            size={"sm"}
            title={<strong>{t("issue_allergen")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {menuAllergens.length > 0 && <Alert color="warning" style={{ padding: '10px', marginBottom: '2px' }}>
                            {menuAllergens.map((dish, index) => {
                                return dish.foodWithAllergens.length > 0 && <li key={index}>{dish.dishName + t("uses_food") +
                                    dish.foodWithAllergens.map(food => {
                                        return food.foodName + t("allergen_text") + food.allergens.map(a => { return a.name }).join(", ")
                                    }).join(", ")} </li>
                            })}
                        </Alert>}
                        {menuFodmaps.length > 0 && <Alert color="light" style={{ padding: '10px', marginBottom: '2px' }}>
                            {menuFodmaps.map((dish, index) => {
                                return dish.foodWithFodmaps.length > 0 && <li key={index}>{dish.dishName + t("uses_food") +
                                    dish.foodWithFodmaps.map(food => {
                                        return food.foodName + t("fodmap_text") + food.fodmaps.map(a => { return a.name }).join(", ")
                                    }).join(", ")} </li>
                            })}
                        </Alert>}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        return (
            <div className="content content-calendar">
                {
                    isLoading ? (
                        <div className="loading-position">
                            <ClipLoader
                                size={60}
                                color={"#e14eca"}
                                loading={isLoading}
                            />
                        </div>
                    ) : (
                        <div>
                            <Row className={'even-height-row'}>
                                <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3 w410'}>
                                    <Card className={'even-height-card'} style={{ height: '485px' }}>
                                        <CardHeader>
                                            <Row>
                                                <Col style={{ textAlign: 'left' }}>
                                                    <CardTitle tag="h3"><strong>{t("menu_details")}</strong></CardTitle>
                                                </Col>
                                                <Col style={{ textAlign: 'right' }}>
                                                    <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup className={'mw-350'}>
                                                <Label>{t("name")}</Label>
                                                <Input
                                                    name="name"
                                                    type="text"
                                                    disabled
                                                    value={menuDetails.name || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-350'}>
                                                <Label>{t("diet")}</Label>
                                                <Input
                                                    name="diet"
                                                    type="text"
                                                    disabled
                                                    value={(menuDetails.diet && menuDetails.diet.name) || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-350'}>
                                                <Label>{t("date")}</Label>
                                                <Input
                                                    name="date"
                                                    disabled
                                                    type="text"
                                                    value={moment(menuDetails.date).format("DD.MM.YYYY.") || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <br />
                                            {inConflictWithDiet ?
                                                <Badge style={{ fontSize: '14px', cursor: 'pointer', width: '350px' }} color="red" pill
                                                    onClick={() => this.toggleModalIssue()}>{t("issue_diet")}
                                                    <br /> {t("click_for_more_info")}</Badge> : null}
                                            {menuAllergens.length > 0 ?
                                                <Badge style={{ fontSize: '14px', cursor: 'pointer', marginTop: '15px', width: '350px' }} color="red" pill
                                                    onClick={() => this.toggleModalIssueAllergen()}>{t("issue_allergen")}
                                                    <br /> {t("click_for_more_info")}</Badge> : null}
                                            {issueModal}
                                            {issueAllergenModal}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6} xl={7} className={'col-xxl-6 col-wxl-6 w820'}>
                                    <Card className={'even-height-card'}>
                                        <CardHeader>
                                            <Row>
                                                <Col style={{ textAlign: 'left' }}>
                                                    <CardTitle
                                                        tag="h3"><strong>{t("macronutrients")}</strong></CardTitle>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={12} align={'center'}>
                                                    <div className={'chart-container'}>
                                                        <Pie
                                                            id="chart"
                                                            data={macronutrientData.macronutrientChartData}
                                                            options={macronutrientData.macronutrientChartOptions}
                                                            width={160} height={90} legend={{ position: 'right' }} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    {macronutrientsTable}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3 w410'}>
                                    <Card className={'even-height-card'}>
                                        <CardHeader>
                                            <Row>
                                                <Col style={{ textAlign: 'left' }}>
                                                    <CardTitle
                                                        tag="h3"><strong>{t("macronutrients_ratios")}</strong></CardTitle>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <div className={'chart-container'}>
                                                <Bar
                                                    data={macronutrientData.macronutrientBarChart}
                                                    height={235}
                                                    options={macronutrientData.macronutrientBarChartOptions}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} xl={12} className={'col-xxl-12 col-wl-12 col-wxl-12'}>
                                    <Row className={'even-height-row'}>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar" onClick={() => this.openEditCard(breakfastData)}><CardHeader><CardTitle
                                                style={{ fontWeight: 'bold' }}>{t("BREAKFAST")}</CardTitle></CardHeader>
                                                {cardBreakfast}
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar"
                                                onClick={() => this.openEditCard(snack1Data)}><CardHeader><CardTitle
                                                    style={{ fontWeight: 'bold' }}>{t("SNACK1")}</CardTitle></CardHeader>
                                                {cardSnack1}
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar"
                                                onClick={() => this.openEditCard(lunchData)}><CardHeader><CardTitle
                                                    style={{ fontWeight: 'bold' }}>{t("LUNCH")}</CardTitle></CardHeader>
                                                {cardLunch}
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar"
                                                onClick={() => this.openEditCard(snack2Data)}><CardHeader><CardTitle
                                                    style={{ fontWeight: 'bold' }}>{t("SNACK2")}</CardTitle></CardHeader>
                                                {cardSnack2}
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar"
                                                onClick={() => this.openEditCard(dinnerData)}><CardHeader><CardTitle
                                                    style={{ fontWeight: 'bold' }}>{t("DINNER")}</CardTitle></CardHeader>
                                                {cardDinner}
                                            </Card>
                                        </Col>
                                        <Col sm={12} md={6} xl={6} className={'col-xxl-4 col-wl-4 col-wxl-2'}>
                                            <Card className="calendar"
                                                onClick={() => this.openEditCard(snack3Data)}><CardHeader><CardTitle
                                                    style={{ fontWeight: 'bold' }}>{t("SNACK3")}</CardTitle></CardHeader>
                                                {cardSnack3}
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                {editMealAndDishModal}
                            </Row>
                            <br />
                            <Row className={'even-height-row row-scroll'}>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <div style={{ display: 'flex' }}>
                                                <Col>
                                                    <CardTitle tag="h3"><strong>{t("clients")}</strong></CardTitle>
                                                </Col>
                                                {!usingClientSpecificMenus && <Col style={{ textAlign: 'right' }}>
                                                    <Button onClick={this.reloadClients}
                                                        className="btn-icon btn-round" color="success" size="sm">
                                                        <i className="fas fa-sync" />
                                                    </Button>
                                                </Col>}
                                            </div>
                                        </CardHeader>
                                        {menuClients.length > 0 ? <CardBody>
                                            <PerfectScrollbar style={{ height: 'auto', maxHeight: '480px' }}>
                                                <Table borderless>
                                                    <thead>
                                                        <tr>
                                                            <th width="24%">{t("first_name")}</th>
                                                            <th width="24%">{t("last_name")}</th>
                                                            <th width="15%">{t("date_of_birth")}</th>
                                                            <th width="10%">{t("sex")}</th>
                                                            <th width="10%">{t("persons_multiplier")}</th>
                                                            <th width="18%">{t("username")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {menuClientsTable}
                                                    </tbody>
                                                </Table>
                                            </PerfectScrollbar>
                                        </CardBody> : null}
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </div>
        );
    }
}

MenuDetails.propTypes = {
    menuEditorData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    menuEditorData: state.menuEditorData,
    auth: state.auth,
    allergenData: state.allergenData,
    fodmapData: state.fodmapData
})

const mapActionsToProps = { getMeals, getDishes, createEntry, updateEntry, getMenuByDiet, reloadClients, getNutrientsOutOfBounds, getAllergensIssue, getFodmapsIssue }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(MenuDetails)))