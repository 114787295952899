import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Row, Col, CardBody, CardTitle, Button, Table } from "reactstrap";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Pie } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import axios from 'axios'

export class MicronutrientsChartCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pieChart: true,
            tableData: false
        }
    }

    handleFileSubmit = (mealId, handleContentDisposition) => {

        const canvasSave = document.getElementById('chart2');
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "");
        axios.post(`/v1/report/meal/chart/micro/${mealId}`, base64Img, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                'Access-Control-Expose-Headers': 'Content-Disposition'
            },
            responseType: 'blob'
        })
            .then(response => {
                handleContentDisposition(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const { data, options, width, height, t, micronutrientsTableData } = this.props;

        let micronutrientsTable = null;
        if (micronutrientsTableData) {
            micronutrientsTable = <PerfectScrollbar style={{ height: 'auto', maxHeight: '428px' }}>
                <Table>
                    <thead>
                        <tr>
                            <th><strong>{t("vitamin")}</strong></th>
                            <th><strong>{t("value")}</strong> ({t("per_person")})</th>
                            <th><strong>{t("unit")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {micronutrientsTableData.map((data, index) => {
                            return <tr key={index}>
                                <td>{t(data.vitaminName)}</td>
                                <td>{data.value}</td>
                                <td>{data.unit}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </PerfectScrollbar>
        }

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("micronutrients")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button disabled={!this.props.mealId || this.state.tableData} type="button" color="success" size="sm" onClick={() => this.handleFileSubmit(this.props.mealId, this.props.handleContentDisposition)}><i className="fas fa-download"></i></Button>
                            {this.state.pieChart ?
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ pieChart: false, tableData: true })}>{t("details")}</Button> :
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ pieChart: true, tableData: false })}>{t("details")}</Button>}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {this.state.pieChart && <Row>
                        <Col md={12} align={'center'}>
                            <div className={'chart-container'}>
                                <Pie
                                    id="chart2"
                                    data={data}
                                    options={options}
                                    legend={{ position: 'bottom', align: 'center' }}
                                    width={width}
                                    height={height} />
                            </div>
                        </Col>
                    </Row>}
                    {this.state.tableData && <Row>
                        <Col md={12}>
                            {micronutrientsTable}
                        </Col>
                    </Row>}
                </CardBody>
            </Card>
        )
    }
}

MicronutrientsChartCard.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    micronutrientsTableData: PropTypes.array.isRequired,
    mealId: PropTypes.number,
    handleContentDisposition: PropTypes.func
}

export default withTranslation()(MicronutrientsChartCard);
